import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Dashboard from "../Dashboard";
import "../sitprep.css";

const SitPrepHome = () => {
  const [currentLevel, setCurrentLevel] = useState(1);
  const totalSteps = 4;

  useEffect(() => {
    // Calculate current level dynamically from localStorage
    const completedSteps = ["addTo72", "editedPlan", "emerCont", "addList"].filter(
      (key) => localStorage.getItem(key)
    ).length;
    setCurrentLevel(completedSteps + 1);
  }, []);

  return (
    <div className="sitprep-app">
      {/* <Navbar currentLevel={currentLevel} totalSteps={totalSteps} /> */}
      <Dashboard />
    </div>
  );
};

export default SitPrepHome;
