import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AppendGroup from '../components/appendGroup';
import HomeFAB from '../components/homepageFAB';
import AllGroups from '../components/allGroups';
import MemberGroups from '../components/Group Comps/GroupLinked';
import OffcanvasMenu from '../components/OffcanvasMenu';
import ProfileButton from '../components/ProfileButton';
import UserInfo from '../components/userInfo';
import { fetchAllUserInfo, fetchAllGroups } from '../Api/apiService';
import { FaPeopleRoof } from "react-icons/fa6";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import NotificationBadge from '../components/Notification Comps/NotificationBadge.js'
import { CgAddR } from "react-icons/cg";
import ManageGroupsModal from '../components/Group Comps/ManageGroupsModal.js';
import { FaCompass, FaUserFriends } from 'react-icons/fa';



const HomePage = ({ deferredPrompt }) => {
  const [activeTab, setActiveTab] = useState('myGroups');
  const [activeSubTab, setActiveSubTab] = useState('createdCircles');
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [linkedGroupData, setLinkedGroupData] = useState([]);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  

   // **Check for 'joingroupId' in localStorage and navigate to /join-invite**
   useEffect(() => {
    const joingroupId = localStorage.getItem('joingroupId');
    if (joingroupId) {
      navigate('/join-invite');
    }
  }, [navigate]);

  
  useEffect(() => {
    if (currentUserEmail) {
      const storedUserData = localStorage.getItem(`userData-${currentUserEmail}`);
      if (storedUserData) {
        try {
          const parsedUserData = JSON.parse(storedUserData);
          setUserProfile(parsedUserData); // Set userProfile from parsed user data
        } catch (error) {
          console.error('Error parsing user data from localStorage:', error);
        }
      }
    }
  }, [currentUserEmail]);
  

  // UseEffect to fetch data and request notification permission
  useEffect(() => {
    const fetchData = async () => {
      if (currentUserEmail) {
        try {
          // Fetch user data
          const userInfo = await fetchAllUserInfo();
          const currentUser = userInfo.find(user => user.userEmail === currentUserEmail);
          setUserData(currentUser);
          localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(currentUser));
         

          // Fetch group data
          const groupsData = await fetchAllGroups();
          const managedGroups = groupsData.filter(group => group.adminEmails.includes(currentUserEmail));
          const joinedGroups = groupsData.filter(
            group => group.memberEmails.includes(currentUserEmail) && !group.adminEmails.includes(currentUserEmail)
          );
          setGroupData(managedGroups);
          setLinkedGroupData(joinedGroups);

          localStorage.setItem(`groupData-${currentUserEmail}`, JSON.stringify(managedGroups));
          localStorage.setItem(`linkedGroupData-${currentUserEmail}`, JSON.stringify(joinedGroups));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      setDataLoaded(true);
    };

    // Initial fetch
    fetchData();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentUserEmail]);

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
    localStorage.setItem('archivedNotifications', JSON.stringify(archivedNotifications));
    setNotificationCount(notifications.length);
  }, [notifications, archivedNotifications]);

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!dataLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center bg-white" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className='mt-5 h-screen'>
        
        {/* <div className='border-b-2 mobilehide'>
          <ul className="d-flex justify-content-between pb-4 ">
          <nav className="d-flex navbar ms-4">
            <img className=" iconslide" src="/images-sitprep/List.png" width="50" alt="" />
            <img className="mx-1  iconslide" src="/images-sitprep/3-4 cross.png" width="75" alt="" />
            <img className=" " src="/images-sitprep/Sitprep Name.png" width="300" alt="" />

            </nav>
            <li className="mt-5 ">
              <ProfileButton userProfile={userProfile} toggleOffcanvas={toggleOffcanvas} />
              <OffcanvasMenu
                userProfile={userProfile}
                offcanvasOpen={offcanvasOpen}
                toggleOffcanvas={toggleOffcanvas}
                handleSignOut={handleSignOut}
              />
            </li>
           
          </ul>
        </div> */}

        <section className="rounded mt-3 container padding-main">
          <div className="">
            <img src='/images/SitPrep Logo new orangered – 3.png' alt='Status Now Icon – dark' width='250' height='250' />
          </div>

          <UserInfo currentUserEmail={currentUserEmail} />
          
          <header className="hidden md:flex rounded-lg ring-1 ring-gray-900/5 bg-gray-700 p-3 justify-between items-center">
          <nav className="flex space-x-10">
            {[
              { tab: 'createdCircles', label: 'Created', icon: <FaPeopleRoof /> },
              { tab: 'joinedCircles', label: 'Joined', icon: <FaUserFriends /> },
              { tab: 'community', label: 'Discover', icon: <FaCompass /> },
            ].map(({ tab, label, icon }) => (
              <button
                key={tab}
                className={`flex items-center space-x-2 py-2 px-4 rounded-full text-gray-800 ${
                  activeSubTab === tab ? 'bg-white font-bold' : 'text-white font-normal hover:bg-green-600 text-dark font-bold'
                }`}
                onClick={() => setActiveSubTab(tab)}
                aria-label={label}
              >
                {icon}
                <span>{label}</span>
              </button>
            ))}
          </nav>
          <div className="flex items-center space-x-2">
            <NotificationBadge count={notificationCount} currentUserEmail={currentUserEmail} />
            <ProfileButton
              userProfile={userProfile}
              toggleOffcanvas={toggleOffcanvas}
              width="40px"
              height="40px"
              fontSize="20px"
              fontWeight="bold"
            />
            <OffcanvasMenu
              userProfile={userProfile}
              offcanvasOpen={offcanvasOpen}
              toggleOffcanvas={toggleOffcanvas}
              handleSignOut={handleSignOut}
            />
          </div>
        </header>




          {activeTab === 'myGroups' && (
            <section>
             

              {activeSubTab === 'createdCircles' && (
                <section className='mt-5 rounded-xl ring-1 ring-gray-900/5 bg-white'>
                  <div className=''>
                    <div className='d-flex justify-content-between me-3 mb-3'>
                      <p className='text-dark fw-semibold text-lg mx-4 mt-4'>My Circles</p>
                      <div className='mt-3 '>
                        <button onClick={() => setShowModal(true)} className="btn bg-success text-sm text-white">
                          Manage Groups
                        </button>
                        {showModal && (
                          <ManageGroupsModal
                            currentUserEmail={currentUserEmail}
                            onClose={() => setShowModal(false)}
                          />
                        )}
                      </div>
                      <Link className='mobileshow text-success text-4xl my-3' to={{ pathname: '/create-group', state: { deferredPrompt } }}>
                        <CgAddR />
                      </Link>
                    </div>
                    <ul className="divide-y divide-gray-100 overflow-hidden pb-4 rounded-xl">
                      {userData && <AppendGroup groupData={groupData} currentUserEmail={currentUserEmail} />}
                    </ul>
                   
                  </div>
                </section>
      
              )}

                {activeSubTab === 'joinedCircles' && (
                  <section className='pt-3 mt-4'>
                    <div className='bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                      <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Associated Circles</p>
                      <ul className="divide-y divide-gray-100 overflow-hidden">
                        <MemberGroups linkedGroupData={linkedGroupData} handleHomePageButton={() => setActiveTab('community')} />
                      </ul>
                    </div>
                  </section>
                )}


              {activeSubTab === 'community' && (
                <section className='pt-3 mt-4'>
                  <div className='mt-3 bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                    <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Other Groups</p>
                    <AllGroups currentUserEmail={currentUserEmail} deferredPrompt={deferredPrompt} initialSearchQuery={searchQuery} />
                  </div>
                </section>
              )}

                {/* Sitprep Emergency Plan */}
                {/* <section className='mt-5 rounded-xl ring-1 ring-gray-900/5 bg-white'>
                  <div className=''>
                    <div className='d-flex justify-content-between me-3 mb-3'>
                      <p className=' fw-semibold text-lg mx-4 mt-4 text-green-700'>My Emergency Plan</p>
                      <div className='mt-3 '>
                      <Link className=" flex btn bg-gray-300 text-sm" to="/sitprep">
                      <img className='px-1 fill-blue-500' src="/images/Sitprep icon.svg" alt="Description of the image" width="28" height="28" />
                      Manage Plan
                     </Link> 
                        
                      </div>
                     
                    </div>
                    <ul className="divide-y divide-gray-100 overflow-hidden pb-4 rounded-xl">
                   
                    </ul>
                   
                  </div>
                </section> */}
            </section>
          )}
        </section>
     
        <div className='mobilehide'>
          <HomeFAB currentUserEmail={currentUserEmail} deferredPrompt={deferredPrompt} />
        </div>
      </main>
     
          <footer className="mobileshow border-t fixed bottom-0 w-full bg-white pb-2 shadow-sm">
          <ul className="mb-2 flex justify-between items-center px-4 py-3">
            <li>
              <button
                className={`flex flex-col items-center py-1 px-3  ${
                  activeSubTab === 'createdCircles'
                    ? 'text-green-700 font-bold text-xl rounded-full'
                    : 'text-gray-700'
                }`}
                onClick={() => setActiveSubTab('createdCircles')}
              >
                <FaPeopleRoof />
                <span className="text-xs mt-1">Created</span>
              </button>
            </li>
            <li>
              <button
                className={`flex flex-col items-center py-1 px-3 text-lg ${
                  activeSubTab === 'joinedCircles'
                    ? 'text-green-700 font-bold text-xl rounded-full'
                    : 'text-gray-700'
                }`}
                onClick={() => setActiveSubTab('joinedCircles')}
              >
                <FaUserFriends />
                <span className="text-xs mt-1">Joined</span>
              </button>
            </li>
            <li>
              <button
                className={`flex flex-col items-center py-1 px-3 text-lg ${
                  activeSubTab === 'community'
                    ? ' text-green-700 font-bold text-xl rounded-full'
                    : 'text-gray-700'
                }`}
                onClick={() => setActiveSubTab('community')}
              >
                <FaCompass />
                <span className="text-xs mt-1">Discover</span>
              </button>
            </li>
            <li>
              <ProfileButton
                userProfile={userProfile}
                toggleOffcanvas={toggleOffcanvas}
                width="40px"
                height="40px"
                fontSize="20px"
                fontWeight="bold"
              />
              <OffcanvasMenu
                userProfile={userProfile}
                offcanvasOpen={offcanvasOpen}
                toggleOffcanvas={toggleOffcanvas}
                handleSignOut={handleSignOut}
              />
            </li>
          </ul>
        </footer>

    </>
  );
};

export default HomePage;
