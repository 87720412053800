import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import SignInWithGoogle from '../signInWithGoogle';
import { Link, useNavigate } from 'react-router-dom';

// Popup Component
const Popup = ({ title, message, onClose, onAction }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-xl font-bold text-gray-800 mb-4">{title}</h2>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onAction}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-lg"
          >
            Login
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Register = () => {
  const { createUserMeth } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let message = '';

    if (name === 'firstName' && !value.trim()) message = 'First Name is required.';
    if (name === 'lastName' && !value.trim()) message = 'Last Name is required.';
    if (name === 'email') {
      if (!value.trim()) {
        message = 'Email is required.';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())) {
        message = 'Enter a valid email address.';
      }
    }
    if (name === 'password') {
      if (!value) {
        message = 'Password is required.';
      } else {
        validatePasswordStrength(value);
      }
    }
    if (name === 'confirmPassword' && value !== formData.password) {
      message = 'Passwords do not match.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: message,
    }));
  };

  const validatePasswordStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const numberCriteria = /\d/.test(password);
    const specialCharCriteria = /[@$!%*?&]/.test(password);

    if (lengthCriteria && numberCriteria && specialCharCriteria) {
      setPasswordStrength('Strong');
    } else if (lengthCriteria && (numberCriteria || specialCharCriteria)) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Weak');
    }
  };

  const validateForm = () => {
    const fields = ['firstName', 'lastName', 'email', 'password', 'confirmPassword'];
    const newErrors = {};

    fields.forEach((field) => {
      if (!formData[field].trim() || (field === 'confirmPassword' && formData[field] !== formData.password)) {
        validateField(field, formData[field]);
        newErrors[field] = true;
      }
    });

    return Object.keys(newErrors).length === 0;
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const userData = {
        userFirstName: formData.firstName,
        userLastName: formData.lastName,
        userEmail: formData.email.trim().toLowerCase(),
        password: formData.password,
      };

      await createUserMeth(userData.userEmail, userData.password, userData);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
      setPasswordStrength('');
    } catch (error) {
      if (error.message.includes('email already in use')) {
        setShowPopup(true); // Show popup on specific error
      } else {
        setErrors({ form: error.message || 'Failed to create account. Please try again later.' });
      }
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const navigateToLogin = () => {
    navigate('/login'); // Navigate to login page
  };

  return (
    <div className="mt-5 bg-gray-50 py-10 px-4 sm:px-6 lg:px-8 max-w-md mx-auto">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-6">Create a Profile</h2>
      {errors.form && <p className="text-red-500 text-center mb-4">{errors.form}</p>}
      <form onSubmit={handleCreateAccount} className="space-y-6">
        {['firstName', 'lastName', 'email'].map((field) => (
          <div key={field} className="relative">
            <label
              htmlFor={field}
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              {field.replace(/([A-Z])/g, ' $1')}
            </label>
            <input
              id={field}
              name={field}
              type="text"
              value={formData[field]}
              onChange={handleInputChange}
              className={`block w-full focus:outline-none sm:text-sm rounded-md border p-2 ${
                errors[field] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-indigo-500'
              }`}
            />
            {errors[field] && <p className="text-red-500 mt-2 text-sm">{errors[field]}</p>}
          </div>
        ))}

        {['password', 'confirmPassword'].map((field) => (
          <div key={field} className="relative">
            <label
              htmlFor={field}
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              {field.replace(/([A-Z])/g, ' $1')}
            </label>
            <div className="mt-1 relative flex items-center rounded-md border p-2">
              <input
                id={field}
                name={field}
                type={
                  field === 'password'
                    ? showPassword
                      ? 'text'
                      : 'password'
                    : showConfirmPassword
                    ? 'text'
                    : 'password'
                }
                value={formData[field]}
                onChange={handleInputChange}
                className={`block w-full focus:outline-none sm:text-sm rounded-md ${
                  errors[field] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-indigo-500'
                }`}
              />
              <button
                type="button"
                onClick={() =>
                  field === 'password'
                    ? setShowPassword((prev) => !prev)
                    : setShowConfirmPassword((prev) => !prev)
                }
                className="ml-2 text-gray-500 hover:text-indigo-500 focus:outline-none"
              >
                {field === 'password' ? (showPassword ? 'Hide' : 'Show') : showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {field === 'password' && passwordStrength && (
              <p
                className={`text-sm mt-1 font-medium ${
                  passwordStrength === 'Strong'
                    ? 'text-green-600'
                    : passwordStrength === 'Medium'
                    ? 'text-yellow-600'
                    : 'text-red-600'
                }`}
              >
                Password strength: {passwordStrength}
              </p>
            )}
            {errors[field] && <p className="text-red-500 mt-2 text-sm">{errors[field]}</p>}
          </div>
        ))}

        <button
          type="submit"
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Create Your Profile
        </button>
      </form>

      <div className="mt-6 flex items-center justify-center">
        <hr className="w-1/4 border-gray-300" />
        <p className="mx-4 text-sm text-gray-500">OR</p>
        <hr className="w-1/4 border-gray-300" />
      </div>

      <div className="mt-6">
        <SignInWithGoogle />
      </div>

      <p className="mt-6 text-sm text-center text-gray-600">
        By creating a profile, you agree to our{' '}
        <Link className="text-indigo-600 hover:text-indigo-500" to="/terms-privacy">
          Terms of Service and Privacy Policy
        </Link>.
      </p>

      {/* Popup for email already in use */}
      {showPopup && (
        <Popup
          title="Email Already in Use"
          message="This email is already associated with an account. You can log in with this email or use a different one."
          onClose={closePopup}
          onAction={navigateToLogin}
        />
      )}
    </div>
  );
};

export default Register;
