import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth } from 'firebase/auth';

// Firebase Initialization
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const auth = getAuth(app);

const uploadFileToStorage = async (file, path) => {
  const storageRef = ref(storage, path);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  } catch (error) {
    console.error('Error uploading file to storage:', error);
    throw error;
  }
};

const requestForToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: "BAqHNMfHOt-A5X8q3u_uJV7Sm9-n9hkPK5YB4D-H4K7RvJqZuEXgm5q-GiTto6a1V2WYk9cAyilugfkJeuRvqQc" });
    if (token) {
      console.log('FCM token:', token);
      return token;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token.', error);
  }
};

const onMessageListener = () => new Promise((resolve) => onMessage(messaging, resolve));

export { firebaseConfig, db, storage, getDownloadURL, ref, deleteObject, auth, messaging, uploadFileToStorage, requestForToken, onMessageListener };