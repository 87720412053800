import React, { useState, useEffect } from "react";
import { Modal, Badge } from "react-bootstrap";
import { FaCheckCircle, FaLeaf, FaMapSigns, FaPhoneAlt, FaBoxOpen } from "react-icons/fa";
import "./sitprep.css";

const Dashboard = () => {
  const [name, setName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(1);

  const motivationalMessages = {
    "Prepared Beginner": "Great start! Every step you take brings you closer to safety.",
    "Safety Strategist": "You're building a solid foundation. Keep up the momentum!",
    "Emergency Pro": "Impressive progress! Your dedication is paving the way to preparedness.",
    "Prepared Champion": "Outstanding achievement! You're fully prepared and ready for any challenge.",
  };

  const totalSteps = 4;
  const levels = [
    "Prepared Beginner",
    "Safety Strategist",
    "Emergency Pro",
    "Prepared Champion",
  ];
  const steps = [
    {
      id: 1,
      title: "72HR Food Menu",
      description: "Plan and track a 72-hour emergency menu.",
      icon: <FaLeaf />,
      link: "./Food Supply.html",
      key: "addTo72",
    },
    {
      id: 2,
      title: "Evacuation Plan",
      description: "Create a plan for safe evacuation.",
      icon: <FaMapSigns />,
      link: "./create_evac_plan.html",
      key: "editedPlan",
    },
    {
      id: 3,
      title: "Emergency Contacts",
      description: "Assign key emergency contacts.",
      icon: <FaPhoneAlt />,
      link: "./emergency_contacts.html",
      key: "emerCont",
    },
    {
      id: 4,
      title: "Additional Supplies",
      description: "List and track extra emergency supplies.",
      icon: <FaBoxOpen />,
      link: "./Additional_Supplies.html",
      key: "addList",
    },
  ];

  useEffect(() => {
    applyName();
    calculateProgress();
  }, []);

  const applyName = () => {
    const prfName = JSON.parse(localStorage.getItem("prfName"));
    if (prfName) {
      setName(prfName[0]?.myName || "");
    } else {
      setModalOpen(true);
    }
  };

  const updateName = () => {
    const newName = document.getElementById("newName").value;
    localStorage.setItem("prfName", JSON.stringify([{ myName: newName }]));
    setName(newName);
    setUpdateModalOpen(false);
  };

  const getStarted = () => {
    localStorage.setItem("prfName", JSON.stringify([{ gotStarted: "Started" }]));
    setModalOpen(false);
  };

  const calculateProgress = () => {
    const completedSteps = steps.filter((step) => localStorage.getItem(step.key)).length;
    setProgress((completedSteps / totalSteps) * 100);
    setCurrentLevel(Math.min(completedSteps + 1, levels.length));
  };

  const handleCompleteStep = (stepKey) => {
    localStorage.setItem(stepKey, "completed");
    calculateProgress();
  };

  const getNextStep = () => {
    const nextStep = steps.find((step) => !localStorage.getItem(step.key));
    return nextStep
      ? `We recommend starting with "${nextStep.title}" to progress your emergency plan.`
      : "You've completed all steps! Great work!";
  };

  const getDynamicMessage = () => {
    if (progress === 100) return "Fantastic job! You've completed your plan!";
    if (progress >= 75) return "Almost there! Just a bit more to go!";
    if (progress >= 50) return "You're halfway to being fully prepared!";
    return "Let's get started on your emergency plan!";
  };

  return (
    <div className="container mx-auto px-6 py-8" style={{ backgroundColor: "#effff9" }}>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-4xl font-bold" style={{ color: "#4b5078" }}>
            Welcome, {name || "Planner"}!
          </h2>
          <p className="text-gray-600">{getDynamicMessage()}</p>
        </div>
        <button
          onClick={() => setUpdateModalOpen(true)}
          className="font-bold py-2 px-4 rounded-md"
          style={{ backgroundColor: "#00a65b", color: "#ffffff" }}
        >
          Update Profile
        </button>
      </div>

      <div
        className="shadow-lg rounded-md p-6 mb-6 bg-gradient-to-r from-green-500 to-green-600 text-white"
      >
        <h3 className="text-2xl font-bold text-center mb-4">{levels[currentLevel - 1]}</h3>
        <p className="text-center text-lg mb-4">{motivationalMessages[levels[currentLevel - 1]]}</p>
        <div className="flex flex-col items-center mb-4">
          <p className="text-xl mb-2 font-medium">{getNextStep()}</p>
          {/* <Badge
            className="px-3 py-1 text-lg"
            style={{ backgroundColor: "#00a65b", color: "#ffffff" }}
          >
            {Math.round(progress)}% Completed
          </Badge> */}
        </div>
        <div className="text-center">
          <p className="text-gray-300">
            {/* Current Level:{" "} */}
            <Badge
              className="px-3 py-1 text-lg"
              style={{ backgroundColor: "#ef320c", color: "#ffffff" }}
            >
              {levels[currentLevel - 1]}
            </Badge>
            {/* {" "}
            of {levels.length} */}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`p-4 rounded-lg shadow-md ${
              localStorage.getItem(step.key) ? "bg-green-100" : "bg-gray-100"
            }`}
          >
            <div className="text-center">
              <div
                className={`w-16 h-16 flex items-center justify-center rounded-full mx-auto mb-4 ${
                  localStorage.getItem(step.key)
                    ? "bg-green-500 text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {localStorage.getItem(step.key) ? <FaCheckCircle /> : step.icon}
              </div>
              <h4 className="font-bold text-lg" style={{ color: "#4b5078" }}>
                {step.title}
              </h4>
              <p className="text-gray-600 text-sm">{step.description}</p>
              <button
                onClick={() => handleCompleteStep(step.key)}
                className={`mt-4 py-2 px-4 rounded-lg font-bold ${
                  localStorage.getItem(step.key)
                    ? "bg-green-500 text-white hover:bg-green-600"
                    : "bg-indigo-500 text-white hover:bg-indigo-600"
                }`}
              >
                {localStorage.getItem(step.key) ? "Completed" : "Start Step"}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modals */}
      <Modal show={isModalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome to SitPrep!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Start your journey to emergency preparedness by adding your name and completing steps.</p>
          <button
            className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md"
            onClick={getStarted}
          >
            Get Started
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={isUpdateModalOpen} onHide={() => setUpdateModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            id="newName"
            className="w-full p-2 border rounded-md"
            type="text"
            placeholder="Enter Your Name"
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray-300 px-4 py-2 rounded-md"
            onClick={() => setUpdateModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="bg-indigo-600 text-white px-4 py-2 rounded-md"
            onClick={updateName}
          >
            Save Name
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
