import React, { useState, useEffect, useCallback } from 'react';
import {
  fetchAllUserInfo,
  fetchAllGroups,
  fetchGroupById,
  updateGroup,
  updateUser,
  fetchUserByEmail,
} from '../Api/apiService';
import IndividualUser from './IndividualUser.js';
import { useNavigate, Link } from 'react-router-dom';
import { FaMapMarkedAlt } from 'react-icons/fa';
import ShareGroup from './shareGroup.js';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SubGroup from './SubGroups.js';

const GroupPageStatusCardWidget = ({
  groupId,
  stopFetchingData,
  setShowRefreshButton,
  setShowSwipeButton,
  userListData,
  setUserListData,
  showGetStatusInfo,
}) => {
  const [filter, setFilter] = useState('NO RESPONSE');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [activeTab, setActiveTab] = useState('Individuals');
  const navigate = useNavigate();

  const fetchGroupData = useCallback(async () => {
    try {
      const groupsData = await fetchAllGroups();
      const group = groupsData.find((g) => g.groupId.toString() === groupId);
      setSelectedGroup(group);
    } catch (error) {
      console.error('Error fetching group data:', error);
    }
  }, [groupId]);

  const fetchUserData = useCallback(async () => {
    if (selectedGroup) {
      try {
        const userEmails = selectedGroup.memberEmails;
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter((user) =>
          userEmails.includes(user.userEmail)
        );
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  }, [selectedGroup, setUserListData]);

  useEffect(() => {
    fetchGroupData();
  }, [fetchGroupData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleFilterClick = (status) => setFilter(status);

  const handleEndRequest = async (groupId) => {
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Inactive' };
      await updateGroup(groupId, updatedGroupData);

      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter((user) =>
        userEmails.includes(user.userEmail)
      );

      const updatePromises = userData.map(async (user) => {
        const newAlertCount = Math.max(user.activeGroupAlertCounts - 1, 0);
        await updateUser(user.id, { ...user, activeGroupAlertCounts: newAlertCount });

        const updatedUser = await fetchUserByEmail(user.userEmail);

        if (updatedUser.activeGroupAlertCounts === 0) {
          await updateUser(user.id, {
            ...updatedUser,
            userStatus: 'NO RESPONSE',
            statusColor: '#99738E',
            userStatusLastUpdated: new Date().toISOString() 
          });
        }
      });

      await Promise.all(updatePromises);

      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));
      const targetGroupIndex = groupDataLocal.findIndex(
        (group) => group.groupId.toString() === groupId
      );

      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Inactive';
      }

      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));

      const updatedAllUserInfo = await fetchAllUserInfo();
      const updatedUserData = updatedAllUserInfo.filter((user) =>
        userEmails.includes(user.userEmail)
      );
      setUserListData(updatedUserData);

      stopFetchingData();
      navigate(`/Linked/${groupId}`);
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };



  const membersWithStatus = userListData.filter(
    (user) => user.userStatus !== 'NO RESPONSE'
  ).length;
  const totalMembers = userListData.length;
  const percentage = totalMembers === 0 ? 0 : Math.round((membersWithStatus / totalMembers) * 100);

  if (!selectedGroup) return <div>Loading...</div>;

  const getStatusCount = (status) => {
    if (status === 'ALL') {
      return userListData.length;
    }
    return userListData.filter((user) => user.userStatus === status).length;
  };

  return (
    <div className="bg-gray-50 min-h-screen pt-4">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 ms-4">
        <Link to={`/Linked/${groupId}`} className="text-gray-700 hover:text-black flex items-center">
          <NavigateBeforeIcon className="mr-2" /> Back
        </Link>

        <div className="flex space-x-4">
        <button 
            className="me-4 text-gray-700 hover:text-black"
            onClick={() => navigate(`/status-map/${groupId}`)}
          >
            <FaMapMarkedAlt size={28} />
          </button>

          <button
            className="bg-red-500 text-white px-4 py-2 mx-4 rounded-full hover:bg-red-600 transition"
            onClick={() => handleEndRequest(groupId)} 
          >
            End Request
          </button>
        </div>
      </div>

      <section className="flex items-center justify-center pt-4 mb-4">
          <div className="flex-1 text-center">
            <h1 className="text-2xl font-bold">{selectedGroup.groupName}</h1>
            <p className="text-gray-500 text-sm">{selectedGroup.description}</p>
          </div>
         
        </section>


      {/* Check-in Percentage */}
      <div className="text-center mb-6">
        <p className="text-lg"><span className="text-xl font-bold" >{percentage}</span>% of members have checked in</p>
      </div>

            <section className="badge flex flex-nowrap overflow-x-auto pb-4 border-b">
        {['NO RESPONSE', 'INJURED', 'HELP', 'SAFE', 'ALL'].map((status) => (
          <button
            key={status}
            onClick={() => handleFilterClick(status)}
            className={`m-1 px-4 py-2 text-sm font-semibold rounded-full transition-all duration-200 border ${
              filter === status
                ? 'border-gray-900 bg-gray-900 text-white hover:bg-gray-700'
                : 'border-gray-300 bg-white text-gray-900 hover:bg-gray-100'
            } ${
              status === 'NO RESPONSE'
                ? 'bg-purple-600 text-gray-900 hover:bg-purple-500'
                : status === 'INJURED'
                ? 'bg-red-500 text-gray-900 hover:bg-red-400'
                : status === 'HELP'
                ? 'bg-yellow-500 text-gray-900 hover:bg-yellow-400'
                : status === 'SAFE'
                ? 'bg-green-500 text-gray-900 hover:bg-green-400'
                : 'bg-blue-500 text-gray-900 hover:bg-blue-400'
            }`}
          >
            {status}
            <span className="ml-2 bg-white text-gray-900 rounded-full px-2">
              {getStatusCount(status)}
            </span>
          </button>
        ))}
      </section>



      {/* Tab Navigation */}
      <div className="ms-4 my-4 flex justify-center  w-75">
        <button
          className={`flex-1 py-2 ${
            activeTab === 'Individuals' ? 'bg-black text-white' : 'bg-gray-200'
          } rounded-l-lg`}
          onClick={() => setActiveTab('Individuals')}
        >
          Individuals
        </button>
        <button
          className={`flex-1 py-2 ${
            activeTab === 'Sub Groups' ? 'bg-black text-white' : 'bg-gray-200'
          } rounded-r-lg`}
          onClick={() => setActiveTab('Sub Groups')}
        >
          Sub Groups
        </button>
      </div>

      {/* Tab Content */}
      <section>
        {activeTab === 'Individuals' && (
          <div className="grid grid-cols-1 sm:grid-cols-2 ">
            {userListData.map((user, index) => (
              (filter === 'ALL' || user.userStatus === filter) && (
                <IndividualUser key={index} userList={[user.userEmail]} />
              )
            ))}
          </div>
        )}
        
        {activeTab === 'Sub Groups' && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {selectedGroup.subGroupIDs.map((subGroupId, index) => (
              <SubGroup key={index} groupId={subGroupId} />
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default GroupPageStatusCardWidget;
