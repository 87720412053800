import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiErrorCircle, BiCheckCircle, BiHelpCircle } from 'react-icons/bi'; 
import { Transition } from '@headlessui/react'; 
import { updateUser, fetchUserByEmail } from '../Api/apiService'; // Correct API function

const UpdateStatusAdmin = ({ closeModal }) => {
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [status, setStatus] = useState('');
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`)) || {});

  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setStatus(userData.userStatus);
    }
  }, [userData]);

  const handleStatusChange = async (newStatus) => {
    let newStatusColor = '';
    
    switch (newStatus) {
      case 'SAFE':
        newStatusColor = '#1BBC9B';
        break;
      case 'INJURED':
        newStatusColor = '#F25A7C';
        break;
      case 'HELP':
        newStatusColor = '#FFC107';
        break;
      default:
        newStatusColor = '#1BBC9B';
    }

    try {
      // Step 1: Fetch the latest user data from backend
      const backendUserData = await fetchUserByEmail(currentUserEmail);

      // Step 2: Create the updatedUserData based on the backend data
      const updatedUserData = {
        ...backendUserData,
        userStatus: newStatus,
        statusColor: newStatusColor,
        userStatusLastUpdated: new Date().toISOString() 
      };

      // Step 3: Update user data in backend
      await updateUser(backendUserData.id, updatedUserData);
      
      // Step 4: Update the local user data with the refreshed data from backend
      localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUserData));

      // Step 5: Update the local component state with the latest data
      setUserData(updatedUserData);
      setStatus(newStatus);

      closeModal();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className=" px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-6">Admin Check-In</h1>
        <p className="mb-6 text-lg leading-8 text-gray-600">
          <span className="font-bold text-gray-900">{userData.userFirstName}</span>, before you check the status of your group, what is your current status?
        </p>
        <div className="space-y-8 flex flex-col items-center">
          <Transition
            show={true}
            as="button"
            className="btn w-full max-w-xs py-3 px-6 bg-red-600 text-white rounded-md shadow-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={() => handleStatusChange('INJURED')}
          >
            <BiErrorCircle className="inline-block mr-2 text-xl" />
            I'm INJURED
          </Transition>
          
          <Transition
            show={true}
            className="btn w-full max-w-xs py-3 px-6 bg-yellow-500 text-white rounded-md  hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
            onClick={() => handleStatusChange('HELP')}
          >
            <BiHelpCircle className="inline-block mr-2 text-xl" />
            I need HELP
          </Transition>

          <Transition
            show={true}
            className="btn w-full max-w-xs py-3 px-6 bg-green-600 text-white rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={() => handleStatusChange('SAFE')}
          >
            <BiCheckCircle className="inline-block mr-2 text-xl" />
            I'm SAFE
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default UpdateStatusAdmin;
