import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchGroupById, fetchAllUserInfo, fetchUserByEmail } from '../Api/apiService';
import GroupPageStatusCardWidget from '../components/GroupPageStatusCard.js';
import ShareGroup from '../components/shareGroup.js';
import UpdateStatusAdmin from '../components/statusUpdateadmin'; // Make sure to import this component


const MyGroup = () => {
  const { groupId } = useParams();
  const [groupData, setGroupData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false); // Modal visibility

  const [showGetStatusInfo, setShowGetStatusInfo] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const intervalRef = useRef(null);

  // Fetch group data
  const fetchGroupData = async () => {
    try {
      const groupData = await fetchGroupById(groupId);
      setGroupData(groupData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching group data:', error);
      setLoading(false);
    }
  };

  // Fetch user data
  const fetchUserData = async () => {
    if (groupData?.memberEmails) {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter(user => groupData.memberEmails.includes(user.userEmail));
       
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };
 
  useEffect(() => {
    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    fetchUserData();
  }, [groupData]);

  // Automatically start fetching data if group alert is active
  useEffect(() => {
    if (groupData?.alert === 'Active' && userListData.length > 0) {
      const currentUserEmail = localStorage.getItem('currentUserEmail');
      const currentUser = userListData.find(user => user.userEmail === currentUserEmail);
      if (currentUser) {
        handleGetStatusClick(currentUser);
      }
    }
  }, [groupData, userListData]);
  

  // Start fetching data at intervals
  const startFetchingData = () => {
    if (intervalRef.current) return;

    setShowGetStatusInfo(true);

    intervalRef.current = setInterval(() => {
      fetchGroupData();
      fetchUserData();
    }, 10000);

    localStorage.setItem(`fetchStatus-${groupId}`, 'Active');
  };

  const stopFetchingData = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setShowGetStatusInfo(false);
    localStorage.removeItem(`fetchStatus-${groupId}`);
  };


  // Function to handle status fetching logic
  const handleGetStatusClick = async (userData) => {
    try {
      const getStatusInfoElement = document.getElementById('getstatusInfo');
      if (getStatusInfoElement) {
        getStatusInfoElement.classList.remove('d-none');
      }
      
      startFetchingData();

      const backendUserData = await fetchUserByEmail(userData.userEmail);
  
      // Ensure `userData` is not undefined before accessing `userStatus`
      if (backendUserData.userStatus === 'NO RESPONSE') {
        setModalVisible(true); // Show modal after status is fetched if condition is met
      }
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!groupData) {
    return <div>Group not found</div>;
  }

  return (
    <>
      <main className="">
        <div className=" px-4 pb-4 pt-5 lg:rounded-t-lg">
         
        </div>
  
        <main>
          <section>
            <GroupPageStatusCardWidget
              groupId={groupId}
              stopFetchingData={stopFetchingData}
             
              userListData={userListData}
              setUserListData={setUserListData}
              showGetStatusInfo={showGetStatusInfo}
            />
          </section>
        </main>
      </main>

      {/* Modal */}
      {modalVisible && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="my-5 py-5 modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                {/* Content from UpdateStatusAdmin */}
                <UpdateStatusAdmin closeModal={() => setModalVisible(false)} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyGroup;