// src/index.js
import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AppContextProvider } from './AppContext';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './serviceWorkerRegistration';

function Index() {
  const pullToRefreshRef = useRef(null);
  const startYRef = useRef(0);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // Function to play a sound when pull-to-refresh is triggered
  const playSound = () => {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // A4 note
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      oscillator.start();
      gainNode.gain.exponentialRampToValueAtTime(0.0001, audioContext.currentTime + 0.5);
      oscillator.stop(audioContext.currentTime + 0.5);
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  };

  useEffect(() => {
    // Handle pull to refresh
    const handleTouchStart = (e) => {
      startYRef.current = e.touches[0].clientY; // Track initial touch position
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const pullDistance = currentY - startYRef.current;

      if (pullDistance > 0) {
        pullToRefreshRef.current.style.transform = `translateY(${pullDistance}px)`; // Move the element down
      }
    };

    const handleTouchEnd = async (e) => {
      const pullDistance = e.changedTouches[0].clientY - startYRef.current;

      if (pullDistance > 300) {
        pullToRefreshRef.current.classList.add('active');

        if (navigator.vibrate) {
          navigator.vibrate(200); // Vibrate for 200ms
        }

        playSound();
        await new Promise((resolve) => setTimeout(resolve, 1000));

        window.location.reload();
        pullToRefreshRef.current.classList.remove('active');
        pullToRefreshRef.current.style.transform = 'translateY(0px)';
      } else {
        pullToRefreshRef.current.style.transform = 'translateY(0px)';
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    // Handle the beforeinstallprompt event
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event); // Store the event for use later
     
      console.log('beforeinstallprompt event captured in index.js');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  return (
    <React.StrictMode>
      <AppContextProvider>
        <div
          ref={pullToRefreshRef}
          style={{ height: '50px', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1 }}
        ></div>
        <App deferredPrompt={deferredPrompt} />
      </AppContextProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

// Register service worker
registerServiceWorker();

// Optional: Log performance metrics
reportWebVitals();