import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { fetchAllGroups, updateUser, updateGroup, fetchUserByEmail } from '../Api/apiService';
import InstallPrompt from './InstallPrompt ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faHome, faUserShield, faLock, faInfoCircle, faUsers } from '@fortawesome/free-solid-svg-icons';

const JoinPrivateGroup = ({deferredPrompt}) => {
  const [groupData, setGroupData] = useState(null);
  const [userData, setUserData] = useState(null); 
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isInstallPromptOpen, setIsInstallPromptOpen] = useState(false);
  const navigate = useNavigate(); 

  // Retrieve currentUserEmail and joingroupId from localStorage
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const joingroupId = localStorage.getItem('joingroupId');

  // ** Fetch Group and User Data **
  useEffect(() => {
    const fetchGroupAndUserData = async () => {
      try {
        const groupsData = await fetchAllGroups();
        const group = groupsData.find((group) => group.groupId === parseInt(joingroupId));
        
        if (group) {
          setGroupData(group);
        } else {
          setError('Group not found.');
        }

        const user = await fetchUserByEmail(currentUserEmail);
        if (user) {
          setUserData(user);
        } else {
          setError('User not found.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while loading user or group data.');
      }
    };

    fetchGroupAndUserData();
  }, [joingroupId, currentUserEmail]);

  /**
   * **Clear LocalStorage Keys with Delay**
   * - Removes joingroupId, hasNavigatedToJoinInvite, and hasReloaded from localStorage
   * - Waits 300ms to ensure keys are fully cleared before navigation
   */
  const clearLocalStorageWithDelay = async () => {
    return new Promise((resolve) => {
      localStorage.removeItem('joingroupId');
      localStorage.removeItem('hasNavigatedToJoinInvite');
      localStorage.removeItem('hasReloaded');
      setTimeout(() => resolve(), 300); // Wait 300ms
    });
  };

  /**
   * **Handle Join Group**
   * - Adds user to the group member list
   * - Adds group to the user's joinedGroupIDs
   * - Clears local storage and navigates to /home
   */
  const handleJoinGroup = async () => {
    try {
      const currentUser = await fetchUserByEmail(currentUserEmail);
  
      if (currentUser && groupData) {
        const updatedGroup = {
          ...groupData,
          memberEmails: [...new Set([...groupData.memberEmails, currentUserEmail])]
        };
  
        await updateGroup(groupData.groupId, updatedGroup);
  
        const updatedUser = {
          ...currentUser,
          joinedGroupIDs: [...new Set([...currentUser.joinedGroupIDs, groupData.groupId])]
        };
  
        await updateUser(currentUser.id, updatedUser);
  
        setSuccessMessage('You have successfully joined the group.');
  
        await clearLocalStorageWithDelay(); // Clear localStorage and wait before navigating
  
        navigate('/home'); // Navigate to the home page
       
      } else {
        setError('User or group not found.');
      }
    } catch (error) {
      console.error('Error joining group:', error);
      setError('An error occurred while joining the group.');
    }
  };

  /**
   * **Get Image Path Based on Group Type**
   * - Returns the image path for the given group type
   */
  const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business': return '/images/Business.svg';
      case 'Church': return '/images/Hospital.svg';
      case 'Extended Family': return '/images/Family.svg';
      case 'Friends': return '/images/Friend.svg';
      default: return '/images/Neighbor.svg';
    }
  };

  if (!groupData || !userData) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-center">
          {error ? (
            <p className="text-red-600">{error}</p>
          ) : (
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 border-t-4 border-blue-500 rounded-full animate-spin"></div>
              <p className="mt-4 text-gray-700">Loading group information...</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  const isUserMember = userData.joinedGroupIDs?.includes(joingroupId);
  const isUserAdmin = userData.managedGroupIDs?.includes(joingroupId);
    // ** Handle Install Prompt Logic **
    const handleInstallPrompt = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt(); // Call prompt() as part of user gesture
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null; // Reset prompt to prevent multiple calls
        });
      }
    };
  

  return (
    <div className="bg-gray-50 min-h-screen p-6">
    {successMessage && (
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
        <span className="block sm:inline">{successMessage}</span>
      </div>
    )}
    
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <img 
          src={getImagePath(groupData.groupType)} 
          alt={groupData.groupType} 
          className="w-full h-64 object-cover" 
        />
        <div className="p-6">
          <h2 className="text-2xl  text-gray-900 mb-4">
            {isUserMember ? `You are already a member of ${groupData.groupName}` 
              : isUserAdmin ? `You manage ${groupData.groupName}` 
              : `Hello ${userData.userFirstName}! Join ${groupData.groupName} and stay connected with those you know by selecting the green button below!`}
          </h2>
          <p className="mt-2 text-gray-700 flex items-center">
            <FontAwesomeIcon icon={faUserShield} className="mr-2" />
            <strong>Group Owner:</strong> {groupData.ownerName}
          </p>
          <p className="mt-2 text-gray-700 flex items-center">
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            <strong>Privacy:</strong> {groupData.privacy}
          </p>
          <p className="mt-2 text-gray-700 flex items-center">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <strong>Purpose:</strong> {groupData.description}
          </p>
          <p className="mt-2 text-gray-700 flex items-center">
            <FontAwesomeIcon icon={faUsers} className="mr-2" />
            <strong>Group Type:</strong> {groupData.groupType}
          </p>
          <div className="mt-6 space-y-4">
            {isUserMember || isUserAdmin ? (
              <button 
                className="w-full bg-gray-300 text-gray-700 py-3 px-4 rounded-lg cursor-not-allowed flex items-center justify-center" 
                disabled
              >
                {isUserMember ? `You are already a member of ${groupData.groupName}` : 'You manage this group'}
              </button>
            ) : (
              <button 
                className="w-full bg-green-600 text-white py-3 px-4 rounded-lg hover:bg-green-500 transition-all flex items-center justify-center" 
                onClick={async () => {
                  await handleJoinGroup();
                  handleInstallPrompt();
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                Join {groupData.groupName}
              </button>
            )}
            <button 
              className="w-full bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition-all flex items-center justify-center" 
              onClick={async () => {
                await clearLocalStorageWithDelay();
                navigate('/home');
              }}
            >
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Go to Home Page
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <InstallPrompt
      deferredPrompt={deferredPrompt}
      isOpen={isInstallPromptOpen}
      onClose={() => setIsInstallPromptOpen(false)}
    />
  </div>
  );
};

export default JoinPrivateGroup;
