import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { requestForToken } from './firebase';
import { createUser as createUserAPI, fetchUserByEmail, updateUser } from './Api/apiService';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const normalizeEmail = (email) => (email ? email.trim().toLowerCase() : '');
  const [authLoading, setAuthLoading] = useState(false);


  const getOrCreateUserProfile = async (email, additionalData = {}) => {
    const normalizedEmail = normalizeEmail(email);

    let userProfile = await fetchUserByEmail(normalizedEmail);
    if (!userProfile) {
      userProfile = await createUserAPI({ ...additionalData, userEmail: normalizedEmail });
    }
    return userProfile;
  };

  const createOrUpdateUserProfile = async (user, additionalData, isNewUser) => {
    const normalizedEmail = normalizeEmail(user.email);
    if (isNewUser) {
      if (!additionalData.userFirstName || !additionalData.userLastName || !additionalData.userEmail) {
        throw new Error('Missing required user data fields for new user.');
      }
      return await createUserAPI({ ...additionalData, userEmail: normalizedEmail });
    }

    let userProfile = await fetchUserByEmail(normalizedEmail);
    if (!userProfile) {
      userProfile = await createUserAPI({ ...additionalData, userEmail: normalizedEmail });
    }
    return userProfile;
  };

  const authHandler = async (user, additionalData = {}, isNewUser = false) => {
    try {
      const userProfile = await createOrUpdateUserProfile(user, additionalData, isNewUser);

      const currentToken = await requestForToken();
      if (currentToken) {
        console.log('FCM token received:', currentToken);
        await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
      }

      setCurrentUser(user);

      localStorage.setItem(`userData-${normalizeEmail(user.email)}`, JSON.stringify(userProfile));
    } catch (error) {
      console.error('Error in authHandler:', { user, additionalData, error });

    }
  };

 

const login = async (email, password) => {
  setAuthLoading(true);
  try {
    const normalizedEmail = normalizeEmail(email);
    const { user } = await signInWithEmailAndPassword(auth, normalizedEmail, password);
    await authHandler(user, { userEmail: normalizedEmail });
    navigate('/home');
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  } finally {
    setAuthLoading(false);
  }
};


  const createUserMeth = async (email, password, additionalData = {}) => {
    const normalizedEmail = normalizeEmail(email);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, normalizedEmail, password);
      await authHandler(user, { ...additionalData, userEmail: normalizedEmail }, true);
      return user;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email already in use');
      } else {
        throw error;
      }
    }
  };

  const logout = async () => {
    if (!currentUser) return;
    const normalizedEmail = normalizeEmail(currentUser.email);
    try {
      const response = await fetchUserByEmail(currentUser.email);
      if (response) {
        await updateUser(response.id, { ...response, fcmtoken: null });
      }
      await signOut(auth);
      setCurrentUser(null);
      localStorage.removeItem(`userData-${normalizedEmail}`);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  const signInWithProvider = async (provider) => {
    try {
      const { user } = await signInWithPopup(auth, provider);

      const additionalData = {
        userFirstName: user.displayName?.split(' ')[0] || '',
        userLastName: user.displayName?.split(' ')[1] || '',
        userEmail: normalizeEmail(user.email),
        userStatus: 'NO RESPONSE',
        statusColor: 'Gray',
        profileImageURL: user.photoURL || '',
        fcmtoken: '',
        managedGroupIDs: [],
        joinedGroupIDs: [],
        groupAlert: 'Inactive',
        subscription: 'Basic',
        subscriptionPackage: 'Monthly',
        dateSubscribed: new Date().toISOString(),
        phone: '',
        title: '',
        address: ''
      };

      await authHandler(user, additionalData, false);
      return user;
    } catch (error) {
      console.error('Error signing in with provider:', error);
      throw error;
    }
  };

  const signInWithGoogle = () =>
    signInWithProvider(new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account consent' }));

  const signInWithFacebook = () =>
    signInWithProvider(new FacebookAuthProvider());

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        try {
          await authHandler(user, {});
        } catch (error) {
          console.error('Error in onIdTokenChanged:', error);
        }
      } else {
        setCurrentUser(null); // Clear current user on logout
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);
  

  const value = {
    currentUser,
    login,
    logout,
    createUserMeth,
    signInWithGoogle,
    signInWithFacebook,
    authLoading,
  };
  

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
