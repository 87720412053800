import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import CreateAccount from './createAccount';
import SignInWithGoogle from '../signInWithGoogle';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Define loading state

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); // Set loading to true during login
  
    try {
      const normalizedEmail = email.trim().toLowerCase();
      await login(normalizedEmail, password);
      navigate('/');
    } catch (error) {
      setError('Invalid credentials. Please check your email and password.');
      console.error('Error during login:', error);
    } finally {
      setLoading(false); // Reset loading after login attempt
    }
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className=" flex items-center justify-center   px-4 sm:px-6 lg:px-8">
    <div className="max-w-md w-full ">
    <div className=" container text-center py-5 my-12 ">
              <div className=' d-flex justify-content-center'>
                
                <img src='/images/Status Now Icon – name – Orange.png' alt='Status Now Icon – dark' width='400' height='400' />
              </div>
              <p className="">Simplify the process of checking in on your loved ones, friends, social circles, and work colleagues by joining Status Now</p>
              
                    <h2 className="mt-3 text-center text-xl font-bold text-gray-700">
          Sign in to your account
        </h2>
        <p className=" text-center text-sm text-gray-600">
          Or{' '}
          <Link
            to="/create-account"
            className="font-medium text-orange-600 hover:text-orange-500"
          >
            create a new account
          </Link>
        </p>
             
            </div>
      
      <div className="pt-5">
        <SignInWithGoogle />
      </div>
      <div className="relative  w-full">
        <div className="relative flex items-center justify-center text-sm font-medium leading-6 my-3">
          <hr className="flex-grow border-t border-gray-500" />
          <span className="px-6 text-gray-500">or continue with</span>
          <hr className="flex-grow border-t border-gray-500" />
        </div>
      </div>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
        </div>
        {error && (
          <p className="text-red-500 text-center text-sm mt-2">{error}</p>
        )}
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          {loading ? 'Logging In...' : 'Sign In'}
        </button>
      </form>
      <p className="mt-2 text-center text-sm text-gray-600">
        By signing in, you agree to our{' '}
        <Link
          to="/terms-privacy"
          className="font-medium text-orange-600 hover:text-orange-500"
        >
          Terms of Use and Privacy Policy
        </Link>
        .
      </p>
    </div>
  </div>
  
  );
};

export default Login;