import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchGroupById, updateGroup, deleteGroup } from '../../Api/apiService';
import AddSubGroup from './addSubGroup';

const ManageGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupOwner = queryParams.get('groupOwner');
  const currentUserEmail = queryParams.get('currentUser');

  const [groupData, setGroupData] = useState({
    groupName: '',
    description: '',
    groupType: '',
    privacy: '',
    zipCode: '',
    groupCode: '',
    address: '',
    latitude: null,
    longitude: null,
  });
  const [groupNameError, setGroupNameError] = useState('');
  const [groupCodeError, setGroupCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const autocompleteRef = useRef(null);
  const isSaveDisabled = groupNameError || groupCodeError || isLoading;

  const groupTypes = [
    'Business', 'Church', 'Extended Family', 'Friends', 'HOA/Neighborhood', 'Household',
    'Hospital or Physician Office', 'Religious Organization', 'Social/Groups', 'School Staff/Classroom', 'Other',
  ];

  // Load group data
  useEffect(() => {
    const loadGroupData = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupData(group);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };
    loadGroupData();
  }, [groupId]);

  // Initialize Google Places Autocomplete
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!document.querySelector(`script[src*="maps.googleapis.com"]`)) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initializeAutocomplete;
        document.body.appendChild(script);
      } else {
        initializeAutocomplete();
      }
    };
    

    const initializeAutocomplete = () => {
      const addressInput = document.getElementById('address');
      if (addressInput && !autocompleteRef.current) {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(addressInput, {
          types: ['geocode'],
          componentRestrictions: { country: 'us' },
        });
        autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
      }
    };

    const handlePlaceSelect = () => {
      if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setGroupData((prevData) => ({
            ...prevData,
            address: place.formatted_address,
            latitude: lat,
            longitude: lng,
          }));
        }
      }
    };

    loadGoogleMapsScript();
  }, []);

  // Update group data
  const handleUpdateGroup = async (e) => {
    e.preventDefault();
    if (groupNameError || groupCodeError) return;

    setIsLoading(true);
    try {
      await updateGroup(groupId, groupData);
      console.log('Group updated successfully!');
      navigate(`/linked/${groupId}`);
    } catch (error) {
      console.error('Error updating group:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete group
  const handleDeleteGroup = async () => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      setIsLoading(true);
      try {
        await deleteGroup(groupId);
        console.log('Group deleted successfully!');
        navigate('/');
      } catch (error) {
        console.error('Error deleting group:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Close form
  const handleClose = () => navigate(-1);

  return (
    <>
      <main className="my-5 py-5 bg-white container rounded">
        <AddSubGroup subgrGroupId={groupId} />
      </main>
      <div className="container px-3 my-5 py-5 bg-white rounded">
        <div className="row">
          <section className="col-md-4 flex justify-content-between">
            <div>
              <p className="h5 fw-bold mb-3 mt-5">Group Information</p>
              <p className="mb-5">Update your group information.</p>
            </div>
            <div>
              {currentUserEmail === groupOwner && (
                <button
                  type="button"
                  className="badge text-sm btn bg-red-400 hover:bg-red-500 me-md-2 flex text-white mt-4"
                  onClick={handleDeleteGroup}
                  disabled={isLoading}
                >
                  {isLoading ? 'Deleting...' : 'Delete Group'}
                </button>
              )}
            </div>
          </section>

          <section className="col-md-8">
            <form onSubmit={handleUpdateGroup}>
              <div className="mb-3">
                <label htmlFor="groupName" className="font-semibold text-gray-900 text-sm">
                  Group Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="groupName"
                  name="groupName"
                  value={groupData.groupName}
                  onChange={(e) => setGroupData({ ...groupData, groupName: e.target.value })}
                  required
                />
                {groupNameError && <p className="text-danger">{groupNameError}</p>}
              </div>

              <div className="mb-3">
                <label htmlFor="groupCode" className="font-semibold text-gray-900 text-sm">
                  Group Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="groupCode"
                  name="groupCode"
                  value={groupData.groupCode}
                  onChange={(e) => setGroupData({ ...groupData, groupCode: e.target.value })}
                  required
                />
                {groupCodeError && <p className="text-danger">{groupCodeError}</p>}
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="font-semibold text-gray-900 text-sm">
                  Group Purpose
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={groupData.description}
                  onChange={(e) => setGroupData({ ...groupData, description: e.target.value })}
                  rows="3"
                ></textarea>
              </div>

              <div className="mb-3">
                <label htmlFor="groupType" className="font-semibold text-gray-900 text-sm">
                  Group Type
                </label>
                <select
                  className="form-select"
                  id="groupType"
                  name="groupType"
                  value={groupData.groupType}
                  onChange={(e) => setGroupData({ ...groupData, groupType: e.target.value })}
                  required
                >
                  <option value="" disabled>Select Group Type</option>
                  {groupTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {groupData.groupType && (
                <>
                  <div className="mb-3">
                    <label htmlFor="privacy" className="font-semibold text-gray-900 text-sm">
                      Privacy
                    </label>
                    <select
                      className="form-select"
                      id="privacy"
                      name="privacy"
                      value={groupData.groupType === 'Household' ? 'Private' : groupData.privacy}
                      onChange={(e) => setGroupData({ ...groupData, privacy: e.target.value })}
                      disabled={groupData.groupType === 'Household'}
                      required
                    >
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address" className="font-semibold text-gray-900 text-sm">
                      Address (Optional, but recommended)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      value={groupData.address}
                      onChange={(e) => setGroupData({ ...groupData, address: e.target.value })}
                    />
                    <p className="text-xs text-gray-500">
                      Sharing your address with group admins helps them in emergencies while prioritizing your privacy.
                    </p>
                  </div>
                </>
              )}

             

              <div className="mt-5 gap-4 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary me-md-2 rounded-pill w-75"
                  disabled={isSaveDisabled}
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-pill w-75"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  Close
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default ManageGroup;