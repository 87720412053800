import React, { useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';



// Welcome Page Component
const WelcomePage = ({ onStart }) => (
  <div className="bg-white">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          How Prepared Are You?
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg text-gray-600">
          Emergencies can happen anytime, anywhere. Take this quick assessment
          to see how ready you are and discover personalized tips to level up
          your emergency preparedness.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={onStart}
            className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
);

// ProgressBar Component
const ProgressBar = ({ currentStep, totalSteps }) => (
  <div className="relative pt-1">
    <div className="flex mb-2 items-center justify-between">
      <div>
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
          Step {currentStep} of {totalSteps}
        </span>
      </div>
    </div>
    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
      <div
        style={{ width: `${(currentStep / totalSteps) * 100}%` }}
        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-600"
      ></div>
    </div>
  </div>
);


// TooltipContent Component
const TooltipModal = ({ isOpen, onClose, title, description, link, checklist, reminder, safetyNote }) => {
    return (
      <Dialog as="div" className="relative z-50" open={isOpen} onClose={onClose}>
        <div className="fixed inset-0 bg-black bg-opacity-50" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-md w-full rounded-lg bg-white p-6 shadow-lg">
            <div className="flex items-start gap-4">
              <div className="flex items-center justify-center rounded-full bg-gray-100 p-2">
                <InformationCircleIcon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <Dialog.Title className="text-lg font-semibold text-gray-900">
                  {title}
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-sm text-gray-600">
                  {description}
                </Dialog.Description>
                {checklist && (
                  <ul className="mt-4 list-disc list-inside text-sm text-gray-600">
                    {checklist.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {reminder && (
                  <p className="mt-4 text-sm font-semibold text-indigo-600">{reminder}</p>
                )}
                {safetyNote && (
                  <p className="mt-2 text-sm text-red-600">{safetyNote}</p>
                )}
                {link && (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-4 inline-block text-sm font-semibold text-indigo-600 hover:text-indigo-800"
                  >
                    Learn more →
                  </a>
                )}
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={onClose}
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  };
  

// Question Component
const Question = ({
    title,
    question,
    options,
    type,
    field,
    currentResponse,
    onAnswer,
    onBack,
    tooltip,
  }) => {
    const [selectedOptions, setSelectedOptions] = useState(
      type === 'multi-select' ? [] : null
    );
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  
    useEffect(() => {
      if (currentResponse !== undefined) {
        setSelectedOptions(currentResponse);
      } else {
        setSelectedOptions(type === 'multi-select' ? [] : null);
      }
    }, [currentResponse, type, field]);
  
    const handleSelect = (value) => {
      if (type === 'multi-select') {
        setSelectedOptions((prev) =>
          prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
        );
      } else {
        setSelectedOptions(value);
        onAnswer(field, value);
      }
    };
  
    const handleSubmit = () => {
      if (type === 'multi-select') {
        onAnswer(field, selectedOptions);
      }
    };
  
    return (
      <fieldset>
        <legend className="text-lg font-semibold text-gray-900">{title}</legend>
        <div className="flex items-center mt-2 relative">
            <p className="text-sm text-gray-600">{question}</p>
            {tooltip && (
                <button
                onClick={() => setIsTooltipOpen(true)}
                className="ml-2 text-indigo-600 hover:text-indigo-800"
                >
                <InformationCircleIcon className="h-5 w-5" />
                </button>
            )}
            </div>
        {type === 'multi-select' ? (
          <div className="mt-6 space-y-5">
            {options.map((option, index) => (
              <div key={index} className="relative flex items-start">
                <input
                  id={`option-${index}`}
                  name="options"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={
                    Array.isArray(selectedOptions) &&
                    selectedOptions.includes(option.value)
                  }
                  onChange={() => handleSelect(option.value)}
                />
                <label
                  htmlFor={`option-${index}`}
                  className="ml-3 text-sm font-medium text-gray-900"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-6 flex flex-col space-y-4">
            {options.map((option, index) => (
              <button
                key={index}
                className={`px-4 py-2 text-sm font-medium border rounded-md ${
                  selectedOptions === option.value
                    ? 'bg-indigo-600 text-white'
                    : 'border-gray-300 text-gray-900 hover:bg-indigo-50'
                }`}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
        <div className="mt-6 flex justify-between">
          {onBack && (
            <button
              onClick={onBack}
              className="rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-400"
            >
              Back
            </button>
          )}
          {type === 'multi-select' && (
            <button
              onClick={handleSubmit}
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            >
              Submit
            </button>
          )}
        </div>
        {tooltip && (
          <TooltipModal
          isOpen={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          {...tooltip} // Pass all tooltip fields dynamically
        />
        )}
      </fieldset>
    );
  };
  
  const Recommendations = ({ responses, calculatedScore }) => {

    const categories = {
      confident: [],
      addToPlan: [],
    };
  
    // Categorize responses dynamically
    Object.entries(responses).forEach(([key, value]) => {
      if (value === "yes") {
        categories.confident.push(key);
      } else if (value === "kinda" || value === "no" || value === "partial") {
        categories.addToPlan.push(key);
      }
    });
  
    // Prioritize "Add to Your Plan" list
    const priorityOrder = [
      "waterSupply",                // Water is essential for survival; lack of clean water is life-threatening in a short time.
      "foodSupply",                 // Food is critical after water; a 72-hour supply is a standard recommendation.
      "communicationPlan",          // Staying connected ensures better response coordination and safety checks.
      "emergencyContacts",          // Communication is vital for coordination and safety.
      "evacuationPlan",             // Knowing how to evacuate can save lives in immediate emergencies.
      "specialNeedsPreparedness",   // Addressing medical needs ensures individuals with chronic or special requirements can survive.
      "firstAidKnowledge",          // First aid can be lifesaving in emergencies and reduces the impact of injuries.
      "powerOutagePlan",            // Power outages can affect food, communication, and safety, making them a high priority.
      "additionalSupplies",         // Supplies like hygiene products and tools are necessary but secondary to basic needs.
      "emergencyKitCheck",          // Ensuring your emergency kit is up-to-date and complete is a critical follow-up task.
      "floodPrep",                  // Natural disasters like floods should be addressed based on local risks.
      "wildfirePrep",               // Wildfires require specialized preparation but are more localized than general needs.
      "earthquakePrep",             // Earthquakes, like wildfires, depend on the geographical area.
      "hurricanePrep",              // Hurricanes have a longer lead time and are seasonal, allowing some preparation.
      "blizzardPrep",               // While serious, blizzards often provide time for preparation and can be mitigated with good planning.
      "financialInsurancePreparedness", // While serious, blizzards often provide time for preparation and can be mitigated with good planning.

    ];
  
    // Define actionable steps for "Add to Your Plan"
    const actionableStep = {
      foodSupply: {
        title: "Stock a 72-Hour Food Supply",
        description:
          "Food is a critical component of emergency preparedness. Ensure you have a 72-hour supply of non-perishable food for all household members.",
        buttonText: "Go to 72-Hour Food Supply",
        link: "www.sitprep.app/food-supply",
      },
      waterSupply: {
        title: "Secure a Reliable Water Supply",
        description:
          "Store at least 1 gallon of water per person per day for drinking and sanitation. Water is essential for survival during any emergency.",
        buttonText: "Go to Water Supply",
        link: "www.sitprep.app/water-supply",
      },
      evacuationPlan: {
        title: "Plan Your Evacuation Routes",
        description:
          "Know your evacuation routes before an emergency occurs. Use SitPrep to map out safe routes and meeting locations.",
        buttonText: "Go to Evacuation Plan",
        link: "www.sitprep.app/evacuation-plan",
      },
      emergencyContacts: {
        title: "Set Up Emergency Contacts",
        description:
          "Communication can save lives during an emergency. Create and share an emergency contact list with family members.",
        buttonText: "Go to Emergency Contacts",
        link: "www.sitprep.app/emergency-contacts",
      },
      powerOutagePlan: {
        title: "Prepare for Power Outages",
        description:
          "Power outages can happen unexpectedly. Ensure you have backup power options, flashlights, and batteries to stay safe and connected.",
        buttonText: "Go to Power Outage Plan",
        link: "www.sitprep.app/power-outage-plan",
      },
      additionalSupplies: {
        title: "Complete Your Emergency Kit",
        description:
          "Add essential items like hygiene products, first aid kits, and tools to your emergency kit. SitPrep helps you create a comprehensive checklist.",
        buttonText: "Go to Additional Supplies",
        link: "www.sitprep.app/additional-supplies",
      },
      specialNeedsPreparedness: {
        title: "Prepare for Special Needs",
        description:
          "Ensure your emergency plan accommodates individuals with disabilities or unique medical requirements in your household.",
        buttonText: "Go to Special Needs Preparedness",
        link: "www.sitprep.app/special-needs",
      },
      communicationPlan: {
        title: "Create a Family Communication Plan",
        description:
          "A strong communication plan ensures your family stays connected during emergencies. Use SitPrep to create and share your plan today.",
        buttonText: "Go to Communication Plan",
        link: "www.sitprep.app/communication-plan",
      },
      petPreparedness: {
        title: "Prepare for Your Pets",
        description:
          "Ensure your pets are safe during emergencies by creating a dedicated plan for their food, shelter, and medical needs.",
        buttonText: "Go to Pet Preparedness",
        link: "www.sitprep.app/pet-preparedness",
      },
      financialInsurancePreparedness: {
        title: "Review Your Financial and Insurance Plans",
        description:
          "Make sure your financial and insurance plans are disaster-ready. Use SitPrep to organize essential documents and resources.",
        buttonText: "Go to Financial Preparedness",
        link: "www.sitprep.app/financial-preparedness",
      },
      earthquakePrep: {
        title: "Prepare for Earthquakes",
        description:
          "Take steps to secure your home and create a plan to stay safe during an earthquake. Practice 'Drop, Cover, and Hold On' drills with your family.",
        buttonText: "Learn Earthquake Preparedness",
        link: "www.sitprep.app/earthquake-prep",
      },
      wildfirePrep: {
        title: "Prepare for Wildfires",
        description:
          "Create defensible space around your home by clearing flammable debris. Prepare an emergency kit and plan evacuation routes.",
        buttonText: "Learn Wildfire Preparedness",
        link: "www.sitprep.app/wildfire-prep",
      },
      hurricanePrep: {
        title: "Prepare for Hurricanes",
        description:
          "Secure your home by installing storm shutters and reinforcing doors. Stock up on essential supplies and identify evacuation routes.",
        buttonText: "Learn Hurricane Preparedness",
        link: "www.sitprep.app/hurricane-prep",
      },
      blizzardPrep: {
        title: "Prepare for Blizzards",
        description:
          "Stay safe during severe winter storms by stocking warm clothing, extra blankets, and a heat source. Prepare for power outages.",
        buttonText: "Learn Blizzard Preparedness",
        link: "www.sitprep.app/blizzard-prep",
      },
    };
    
    const prioritizedAddToPlan = categories.addToPlan
    .filter((key) => actionableStep.hasOwnProperty(key)) // Filter to include only valid keys
    .sort((a, b) => priorityOrder.indexOf(a) - priorityOrder.indexOf(b));
  
    const topPriority = prioritizedAddToPlan.length > 0 ? prioritizedAddToPlan[0] : null;

    console.log("Prioritized Add To Plan:", prioritizedAddToPlan);
    console.log("Top Priority:", topPriority);
   
  
    
  
    // Default action for perfect scores
    const defaultAction = {
      title: "Maintain and Share Your Emergency Plan",
      description:
        "It seems like you have a solid plan so far! With SitPrep, you can securely store your plan, set reminders to revisit it, and share it with your family.",
      buttonText: "Go to SitPrep Dashboard",
      link: "www.sitprep.app/dashboard",
    };
  
    // Determine the next step
    const action = actionableStep[topPriority] ?? defaultAction;
    console.log("Selected Action:", action);
    console.log("Priority Order Indexes:");
    prioritizedAddToPlan.forEach((item) =>
      console.log(`${item}: ${priorityOrder.indexOf(item)}`)
    );
    
  
    return (
      <div className="bg-gray-50 py-24 sm:py-32">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          {/* Feedback Section */}
          <h2 className="text-center text-base font-semibold text-indigo-600">
            {calculatedScore === 100
              ? "You're at the Top of Your Preparedness Game!"
              : "Preparedness Insights Tailored for You"}
          </h2>
          <p className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            {calculatedScore === 100
              ? "Fantastic Work! Keep Your Plan Ready with SitPrep."
              : "Here's How SitPrep Can Support You"}
          </p>
          <div className="mt-4 text-center">
            <p className="text-lg text-gray-700">
              {calculatedScore === 100
                ? "You’ve done amazing work, but emergencies evolve. Use SitPrep to maintain your plan, set reminders, and stay prepared."
                : "Every step you take now strengthens your emergency plan. Let SitPrep make it seamless and effective."}
            </p>
          </div>
  
          {/* Strengths Section */}
          <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold text-gray-900">Your Strengths</h3>
              <ul className="list-disc list-inside mt-4 text-gray-600">
                {categories.confident.length > 0 ? (
                  categories.confident.map((item, index) => (
                    <li key={index}>{item.replace(/([A-Z])/g, " $1")}</li>
                  ))
                ) : (
                  <p className="text-sm text-gray-500">No confident items yet.</p>
                )}
              </ul>
            </div>
  
            {/* Add to Your Plan Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold text-gray-900">Add to Your Plan</h3>
              {prioritizedAddToPlan.length === 0 ? (
                <p className="mt-4 text-sm text-gray-500">
                  You're fully prepared—amazing work!
                </p>
              ) : (
                <ul className="list-disc list-inside mt-4 text-gray-600">
                  {prioritizedAddToPlan.map((item, index) => (
                    <li key={index}>{item.replace(/([A-Z])/g, " $1")}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
  
          {/* Next Steps Card */}
          <div className="mt-10 bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold text-indigo-600">
              Next Steps: {action.title}
            </h3>
            <p className="mt-2 text-gray-600">{action.description}</p>
            <p className="mt-4 text-gray-700">
              {calculatedScore === 100
                ? "Keep your family safe by sharing your plan, setting reminders, and staying proactive."
                : "Take this step today to strengthen your preparedness and bring peace of mind to your family."}
            </p>
            <div className="mt-6 text-center">
              <a
                href={action.link}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Start with This Step
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
  const calculateScore = (responses) => {
    // Filter responses to include only questions with values of "yes," "kinda," "partial," or "no"
    const validResponses = Object.values(responses).filter(
      (value) => value === "yes" || value === "kinda" || value === "partial" || value === "no"
    );
  
    const yesCount = validResponses.filter((value) => value === "yes").length;
    const kindaCount = validResponses.filter((value) => value === "kinda" || value === "partial").length;
  
    // Calculate the score based on valid responses
    return Math.round(((yesCount + 0.5 * kindaCount) / validResponses.length) * 100);
  };
  
const EmergencyAssessment = () => {
    const [step, setStep] = useState(0);
    const [responses, setResponses] = useState({
      selfGrade: 0,
      naturalDisasters: [],
      foodSupply: "",
      waterSupply: "",
      emergencyContacts: "",
      evacuationPlan: "",
      additionalSupplies: "",
      floodPrep: "",
      earthquakePrep: "",
      wildfirePrep: "",
      hurricanePrep: "",
      powerOutagePlan: "",
      firstAidKnowledge: "",
      emergencyKitCheck: "",
      specialNeedsPreparedness: "",
      communicationPlan: "",
      blizzardPrep: "",
    });
    const [filteredQuestions, setFilteredQuestions] = useState([]);
  
    const allQuestions = [
        // Category 1: General Preparedness
        {
            title: "Self-Grading (General Preparedness)",
            question: "How prepared do you feel to handle an emergency right now?",
            options: [
              { label: "Not Prepared: I don’t have a plan or supplies yet.", value: 25 },
              { label: "Somewhat Prepared: I’ve started but have more to do.", value: 50 },
              { label: "Mostly Prepared: I feel confident but could improve.", value: 75 },
              { label: "Fully Prepared: I’m ready for anything!", value: 100 },
            ],
            tooltip: {
              title: "How Prepared Are You?",
              description:
                "This question helps you reflect on your current level of readiness. Think about factors like supplies, plans, and your ability to respond to emergencies. This self-check is the first step to improving your preparedness.",
              link: "https://www.ready.gov/plan",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_checklist_icon.png",
            },
            field: "selfGrade",
          },
          
          {
            title: "Emergency Contacts (General Preparedness)",
            question: "Have you created and shared an emergency contact list with your family?",
            options: [
              {
                label: "Yes: Everyone knows the list and how to use it.",
                value: "yes",
                description: "Great job! Ensure the list is easily accessible and regularly updated.",
              },
              {
                label: "Somewhat: We have a list, but it’s incomplete or not shared.",
                value: "kinda",
                description: "You're on the right track. Let's complete and share it with your family.",
              },
              {
                label: "No: We don’t have an emergency contact list yet.",
                value: "no",
                description: "No worries! Start with a simple list and share it with your household.",
              },
            ],
            tooltip: {
              title: "Why Emergency Contacts Matter",
              description:
                "During emergencies, having a clear, shared emergency contact list can save valuable time and ensure quick communication. Include an out-of-town contact to help coordinate if local lines are overwhelmed.",
              link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/make-a-plan.html",
              visual: "https://www.redcross.org/content/dam/redcross/icons/emergency-contacts-icon.png",
              checklist: [
                "✅ List at least three contacts, including one out-of-town contact.",
                "✅ Share the list with all family members and discuss how to use it.",
                "✅ Store the list in accessible locations, such as on your phone or printed in your emergency kit.",
              ],
              reminder: "📅 Update your emergency contacts every six months or whenever there are changes.",
              safetyNote: "💡 Ensure children in your household know how to use the list and make emergency calls.",
            },
            field: "emergencyContacts",
          },
          

            {
                title: "Communication Plan (General Preparedness)",
                question: "Have you created and practiced a family communication plan for emergencies?",
                options: [
                    { label: "Yes: We have a clear plan, and everyone knows it.", value: "yes" },
                    { label: "Somewhat: We’ve discussed it, but it’s not formalized or practiced.", value: "kinda" },
                    { label: "No: We don’t have a communication plan yet.", value: "no" },
                ],
                tooltip: {
                    title: "Why a Communication Plan is Essential",
                    description:
                    "Emergencies can disrupt communication systems. A well-thought-out plan with designated meeting points, out-of-town contacts, and practiced protocols ensures everyone stays connected and safe.",
                    link: "https://www.ready.gov/plan",
                    visual: "https://www.ready.gov/sites/default/files/2020-03/ready_communication_plan_icon.png",
                },
                field: "communicationPlan",
                },

                {
                    title: "Evacuation Plan (General Preparedness)",
                    question: "Do you have a clear evacuation plan with designated routes and meeting points for your family?",
                    options: [
                      { label: "Yes: Everyone knows the plan and has practiced it.", value: "yes" },
                      { label: "Somewhat: We’ve discussed it, but it’s incomplete or not practiced.", value: "kinda" },
                      { label: "No: We don’t have an evacuation plan yet.", value: "no" },
                    ],
                    tooltip: {
                      title: "Planning Evacuation Routes: A Lifesaver in Emergencies",
                      description:
                        "An evacuation plan is critical in emergencies like fires or natural disasters. Ensure all family members know the routes, practice them regularly, and include a plan for pets or individuals with special needs.",
                      link: "https://www.ready.gov/plan",
                      visual: "https://www.ready.gov/sites/default/files/2020-03/ready_evacuation_plan_icon.png",
                      checklist: [
                        "✅ Map out primary and secondary evacuation routes.",
                        "✅ Identify a safe meeting location for your family.",
                        "✅ Account for pets, elderly, or disabled family members.",
                        "✅ Keep important documents and supplies ready to grab.",
                      ],
                      reminder: "📅 Regularly review and practice your evacuation plan, especially with children.",
                    },
                    field: "evacuationPlan",
                  },
                  
          // Category 3: Core Supplies
          {
            title: "72-Hour Food Supply (Core Supplies)",
            question: "Do you have enough non-perishable food to last 72 hours for each household member?",
            options: [
              { label: "Yes: We have a fully stocked 3-day supply.", value: "yes" },
              { label: "Somewhat: We have some basics but need more.", value: "kinda" },
              { label: "No: We don’t have enough food stocked yet.", value: "no" },
            ],
            tooltip: {
              title: "Why a 72-Hour Food Supply is Essential",
              description:
                "During emergencies, access to food may be limited. A 72-hour supply ensures your family has enough to eat during the critical initial days of a disaster. Focus on non-perishable, easy-to-prepare foods.",
              link: "https://www.ready.gov/kit",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_food_supply_icon.png",
              checklist: [
                "✅ Include a mix of canned goods, dried fruits, and protein bars.",
                "✅ Stock items that require minimal preparation or cooking.",
                "✅ Rotate supplies periodically to avoid expiration.",
                "✅ Consider dietary needs for all family members, including allergies.",
              ],
              reminder: "📅 Regularly review and restock your food supply to ensure freshness.",
            },
            field: "foodSupply",
          },
          
          {
            title: "Power Outage Plan (Core Supplies)",
            question: "Are you prepared for extended power outages with a backup plan?",
            options: [
              { label: "Yes: We have a generator or alternative power source ready.", value: "yes" },
              { label: "Somewhat: We have flashlights, batteries, or basic supplies.", value: "kinda" },
              { label: "No: We don’t have a power outage plan yet.", value: "no" },
            ],
            tooltip: {
              title: "Why Plan for Power Outages?",
              description:
                "Extended power outages can disrupt daily life. A backup plan ensures access to light, communication, and essential appliances during blackouts.",
              link: "https://www.ready.gov/power-outages",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_power_outage_icon.png",
              checklist: [
                "✅ Portable generator or alternative power source.",
                "✅ Battery packs for phones and small devices.",
                "✅ Flashlights and extra batteries for every household member.",
                "✅ Non-electric cooking tools, like a camping stove.",
                "✅ Manual can opener for canned goods.",
              ],
              reminder: "💡 Test your generator regularly and store fuel safely if applicable.",
              safetyNote: "⚠️ Avoid using generators indoors to prevent carbon monoxide poisoning."
            },
            field: "powerOutagePlan",
          },
          
          {
            title: "Additional Supplies (Core Supplies)",
            question: "Is your emergency kit fully stocked with essential supplies?",
            options: [
              { label: "Yes: We have all the necessary items and it’s ready to use.", value: "yes" },
              { label: "Somewhat: We have a few key items but it needs improvement.", value: "kinda" },
              { label: "No: We don’t have an emergency kit yet.", value: "no" },
            ],
            tooltip: {
              title: "Creating a Comprehensive Emergency Kit",
              description:
                "A well-stocked emergency kit is vital for handling unexpected situations. Be sure to include items for health, safety, and communication needs.",
              link: "https://www.ready.gov/kit",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_emergency_kit_icon.png",
              checklist: [
                "✅ Flashlight with extra batteries.",
                "✅ First aid kit with instructions.",
                "✅ Multi-tool and duct tape.",
                "✅ Prescription medications and copies of medical information.",
                "✅ Dust masks for filtering contaminated air.",
                "✅ NOAA weather radio (battery-powered or hand-crank).",
                "✅ Emergency whistle to signal for help.",
              ],
              reminder: "🔄 Review your kit every 6 months to ensure supplies are fresh and functional.",
              safetyNote: "💊 Store medications in a cool, dry place and check expiration dates regularly."
            },
            field: "additionalSupplies",
          },
          
          {
            title: "Pet Preparedness (Special Considerations)",
            question: "Have you prepared an emergency plan and supplies to keep your pets safe?",
            options: [
              { label: "Yes: We have everything ready for our pets in case of an emergency.", value: "yes" },
              { label: "Somewhat: We have a few items but need to improve.", value: "partial" },
              { label: "No: We haven’t planned for our pets yet.", value: "no" },
            ],
            tooltip: {
              title: "Preparing Your Pets for Emergencies",
              description:
                "Pets are family too! Ensure they’re included in your emergency plan by preparing supplies and identifying evacuation options.",
              link: "https://www.ready.gov/pets",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_pet_preparedness_icon.png",
              checklist: [
                "✅ 3-day supply of pet food and water.",
                "✅ Medications and copies of veterinary records.",
                "✅ ID tags, leashes, and carriers.",
                "✅ Familiar items like toys or a blanket to reduce stress.",
                "✅ Waste bags for sanitation.",
                "✅ Contact information for local shelters and pet-friendly accommodations.",
              ],
              reminder: "🐾 Keep your pets’ vaccinations up to date and ensure they have proper identification.",
              safetyNote: "🚨 Practice evacuation drills that include your pets so they feel comfortable in a crisis."
            },
            field: "petPreparedness",
          },
          
          {
            title: "Special Needs Preparedness (Special Considerations)",
            question: "Have you created an emergency plan that accommodates individuals with disabilities or special needs?",
            options: [
              { label: "Yes: We’ve created a detailed and inclusive plan.", value: "yes" },
              { label: "Somewhat: We have a plan, but it needs more details.", value: "partial" },
              { label: "No: We haven’t addressed this yet.", value: "no" },
            ],
            tooltip: {
              title: "Inclusive Emergency Planning",
              description:
                "Emergencies can present unique challenges for individuals with disabilities or special needs. Plan ahead to ensure everyone can evacuate safely, access necessary medical care, and communicate effectively during a crisis.",
              link: "https://www.ready.gov/disabilities",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_disability_preparedness_icon.png",
              checklist: [
                "✅ Include mobility aids, assistive devices, and spare batteries in your emergency kit.",
                "✅ Maintain a list of medications, dosages, and emergency contact information.",
                "✅ Plan transportation for individuals with mobility challenges.",
                "✅ Include communication tools for individuals with hearing or speech impairments.",
                "✅ Create a network of neighbors or caregivers who can provide assistance if needed.",
              ],
              reminder: "🛠️ Regularly update your plan to reflect changing needs and equipment.",
              safetyNote: "🚨 Identify accessible shelters and emergency services in your area.",
            },
            field: "specialNeedsPreparedness",
          },
          
          {
            title: "Financial and Insurance Preparedness (Special Considerations)",
            question: "Have you reviewed your financial and insurance plans to ensure they cover potential disaster expenses?",
            options: [
              { label: "Yes: We’re fully prepared and covered.", value: "yes" },
              { label: "Partially: We have some coverage, but it needs improvement.", value: "partial" },
              { label: "No: We haven’t reviewed or prepared yet.", value: "no" },
            ],
            tooltip: {
              title: "Financial and Insurance Preparedness",
              description:
                "Financial readiness is key during emergencies. Ensuring that you have adequate insurance and cash reserves can help protect your family from unexpected costs.",
              link: "https://www.ready.gov/financial-preparedness",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_financial_preparedness_icon.png",
              checklist: [
                "✅ Keep cash in small denominations for emergencies where ATMs may be inaccessible.",
                "✅ Ensure your home insurance policy covers natural disasters common in your area.",
                "✅ Review your health and auto insurance for adequate coverage during emergencies.",
                "✅ Safeguard important documents in a waterproof and fireproof container.",
                "✅ Create an inventory of valuable household items for insurance claims.",
              ],
              reminder: "💡 Regularly update your financial and insurance plans to reflect changes in your life and environment.",
              safetyNote: "🚨 Consider setting up direct deposit and digital access to your accounts to ensure financial continuity.",
            },
            field: "financialInsurancePreparedness",
          },
          
          {
            title: "Common Natural Disasters (Disaster-Specific)",
            question: "Which natural disasters are common in your area? (Select all that apply)",
            options: [
              { label: "Floods", value: "flood" },
              { label: "Earthquakes", value: "earthquake" },
              { label: "Hurricanes", value: "hurricane" },
              { label: "Wildfires", value: "wildfire" },
              { label: "Blizzards or Snow Storms", value: "blizzard" },
            ],
            type: "multi-select",
            tooltip: {
              title: "Understanding Local Risks",
              description:
                "Knowing the types of natural disasters that may affect your area helps you prepare specific plans and stock necessary supplies.",
              link: "https://www.ready.gov/be-informed",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_natural_disasters_icon.png",
              checklist: [
                "✅ Check with local emergency management offices for risk maps and hazard assessments.",
                "✅ Stay informed about weather forecasts and alerts from reliable sources.",
                "✅ Understand the seasonal patterns of disasters in your area (e.g., hurricanes in summer, blizzards in winter).",
                "✅ Identify the primary risks at your current location (e.g., near rivers, coastal areas, or forests).",
              ],
              reminder: "💡 Keep track of local disaster histories and seasonal trends to remain vigilant.",
              safetyNote: "🚨 Ensure your home insurance policy covers the natural disasters prevalent in your area.",
            },
            field: "naturalDisasters",
          },
          
          {
            title: "Blizzard Preparedness (Disaster-Specific)",
            question: "Do you have a plan to stay warm and safe during a blizzard or severe winter storm?",
            options: [
              { label: "Yes, we’re fully prepared with all essentials.", value: "yes" },
              { label: "Partially prepared, we have some items.", value: "partial" },
              { label: "Not at all, we’re unprepared.", value: "no" },
            ],
            tooltip: {
              title: "Staying Safe During Winter Storms",
              description:
                "Blizzards can cause power outages, extreme cold, and limited access to essential supplies. Be prepared to shelter in place and stay safe.",
              link: "https://www.ready.gov/winter-weather",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_blizzard_preparedness_icon.png",
              checklist: [
                "✅ Stock a 3-day supply of food and water for each household member.",
                "✅ Keep extra blankets, warm clothing, and a heat source like a generator or wood stove.",
                "✅ Ensure your car has emergency supplies if travel is unavoidable.",
                "✅ Prepare a battery-powered weather radio to stay informed about updates.",
              ],
              reminder: "💡 Prevent frostbite and hypothermia by staying warm and dry.",
              safetyNote: "🚨 Avoid using fuel-burning appliances indoors without proper ventilation to prevent carbon monoxide poisoning.",
            },
            field: "blizzardPrep",
            condition: (responses) => responses.naturalDisasters.includes("blizzard"),
          },
          
          {
            title: "Flood Preparedness (Disaster-Specific)",
            question: "Have you taken measures to protect your home and belongings from flooding?",
            options: [
              { label: "Yes, we’re fully prepared with flood barriers and precautions.", value: "yes" },
              { label: "Partially prepared, we’ve considered some options.", value: "partial" },
              { label: "Not at all, we’re unprepared.", value: "no" },
            ],
            tooltip: {
              title: "Protecting Your Home from Floods",
              description:
                "Floods are the most common natural disaster in the U.S. Taking preventive steps can minimize damage and ensure safety during a flood event.",
              link: "https://www.ready.gov/floods",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_flood_preparedness_icon.png",
              checklist: [
                "✅ Use sandbags or install flood barriers around entry points.",
                "✅ Elevate important appliances like the water heater and electrical panels.",
                "✅ Move valuables and critical documents to higher levels.",
                "✅ Prepare an emergency kit and evacuation plan for quick action.",
              ],
              reminder: "💡 Avoid walking or driving through floodwaters; even shallow water can be dangerous.",
              safetyNote: "🚨 Keep updated on local alerts and evacuate promptly if authorities advise.",
            },
            field: "floodPrep",
            condition: (responses) => responses.naturalDisasters.includes("flood"),
          },
          
          {
            title: "Earthquake Preparedness (Disaster-Specific)",
            question: "Have you taken steps to secure your home and developed a plan for earthquakes?",
            options: [
              { label: "Yes, we’ve secured furniture and have a plan.", value: "yes" },
              { label: "Partially, we’ve started securing items and discussing a plan.", value: "partial" },
              { label: "Not at all, we’re unprepared.", value: "no" },
            ],
            tooltip: {
              title: "Earthquake Preparedness Tips",
              description:
                "Taking preventive steps can reduce injuries and damage during an earthquake. Secure heavy furniture, identify safe spots, and prepare essential supplies.",
              link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html",
              visual: "https://www.redcross.org/content/dam/redcross/icons/earthquake-preparedness-icon.png",
              checklist: [
                "✅ Anchor heavy furniture like bookshelves and cabinets to the wall.",
                "✅ Secure water heaters and other appliances to prevent tipping.",
                "✅ Identify safe spots in each room, such as under sturdy tables.",
                "✅ Practice 'Drop, Cover, and Hold On' drills with family members.",
                "✅ Assemble an earthquake-specific emergency kit, including a whistle.",
              ],
              reminder: "💡 Avoid standing in doorways or near windows during an earthquake.",
              safetyNote: "🚨 After an earthquake, check for hazards like gas leaks or structural damage before re-entering buildings.",
            },
            field: "earthquakePrep",
            condition: (responses) => responses.naturalDisasters.includes("earthquake"),
          },
          
          {
            title: "Wildfire Preparedness (Disaster-Specific)",
            question: "Have you taken steps to create a defensible space and prepare your home for wildfires?",
            options: [
              { label: "Yes, I’ve cleared flammable materials and prepared my home.", value: "yes" },
              { label: "Partially, I’ve started clearing and making plans.", value: "partial" },
              { label: "Not at all, I haven’t started yet.", value: "no" },
            ],
            tooltip: {
              title: "How to Prepare for Wildfires",
              description:
                "Creating defensible space around your home can help reduce wildfire risks and protect your property.",
              link: "https://www.ready.gov/wildfires",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_wildfire_preparedness_icon.png",
              checklist: [
                "✅ Clear dry leaves, pine needles, and other flammable debris from gutters and roofs.",
                "✅ Trim trees and shrubs to keep branches at least 10 feet away from your home.",
                "✅ Create a 30-foot buffer zone free of combustible materials like firewood stacks.",
                "✅ Use fire-resistant materials for decks, siding, and roofing when possible.",
                "✅ Keep a garden hose or fire extinguisher readily accessible in case of small fires.",
              ],
              reminder: "💡 Plan evacuation routes and pack an emergency kit in case of immediate evacuation.",
              safetyNote: "🚨 Check local fire hazard maps and monitor weather conditions for potential risks.",
            },
            field: "wildfirePrep",
            condition: (responses) => responses.naturalDisasters.includes("wildfire"),
          },
          
          {
            title: "Hurricane Preparedness (Disaster-Specific)",
            question: "Have you installed storm shutters and made plans for extended power outages during a hurricane?",
            options: [
              { label: "Yes, my home is fully hurricane-ready.", value: "yes" },
              { label: "Partially, I have some supplies but need more.", value: "partial" },
              { label: "Not at all, I haven’t started preparing.", value: "no" },
            ],
            tooltip: {
              title: "How to Prepare for Hurricanes",
              description:
                "Proper preparation can protect your home and family during a hurricane. Essential steps include securing windows, doors, and ensuring backup power is available.",
              link: "https://www.ready.gov/hurricanes",
              visual: "https://www.ready.gov/sites/default/files/2020-03/ready_hurricane_preparedness_icon.png",
              checklist: [
                "✅ Install storm shutters or reinforce windows with plywood.",
                "✅ Secure outdoor furniture and loose items to prevent them from becoming projectiles.",
                "✅ Ensure you have an emergency power supply like a generator or battery backups.",
                "✅ Stock up on essential supplies like food, water, and medications for at least 3 days.",
                "✅ Plan evacuation routes and monitor local hurricane alerts."
              ],
              reminder: "💡 Keep important documents in a waterproof container and have a go-bag ready in case of evacuation.",
              safetyNote: "🚨 Follow local authorities’ evacuation orders and stay informed about the hurricane's path.",
            },
            field: "hurricanePrep",
            condition: (responses) => responses.naturalDisasters.includes("hurricane"),
          },
          
      ]
      
  
      const handleAnswer = (field, value) => {
        setResponses((prev) => ({ ...prev, [field]: value }));
        setStep((prev) => prev + 1);
      };
    
      const handleBack = () => {
        setStep((prev) => (prev > 0 ? prev - 1 : 0));
      };
    
      useEffect(() => {
        const generalQuestions = allQuestions.filter((q) => !q.condition);
        const conditionalQuestions = allQuestions.filter(
          (q) => q.condition && q.condition(responses)
        );
        setFilteredQuestions([...generalQuestions, ...conditionalQuestions]);
      }, [responses]);
    
      return (
        <div className="container mx-auto mt-10 bg-white rounded-lg p-5">
          {step === 0 && <WelcomePage onStart={() => setStep(1)} />}
          {step > 0 && step <= filteredQuestions.length && (
            <>
              <ProgressBar currentStep={step} totalSteps={filteredQuestions.length} />
              <Question
                {...filteredQuestions[step - 1]}
                currentResponse={responses[filteredQuestions[step - 1].field]}
                onAnswer={(field, value) => handleAnswer(field, value)}
                onBack={step > 1 ? handleBack : null}
              />
            </>
          )}
          {step > filteredQuestions.length && (
            <Recommendations
              responses={responses}
              selfGrade={responses.selfGrade}
              calculatedScore={calculateScore(responses)}
            />
          )}
        </div>
        
      );
      
    };
    
    export default EmergencyAssessment;