import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/24/solid';

const NotificationBadge = ({ currentUserEmail }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const storedNotifications = JSON.parse(localStorage.getItem('notifications')) || [];
    const userNotifications = storedNotifications.filter(notification => notification.userEmail === currentUserEmail);
    setCount(userNotifications.length);
  }, [currentUserEmail]);

  return (
    <Link to={`/notifications?userEmail=${currentUserEmail}`} className="relative inline-flex items-center p-3 text-white">
      <BellIcon className="w-6 h-6" />
      {count > 0 && (
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
          {count}
        </span>
      )}
    </Link>
  );
};

export default NotificationBadge;
