import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiErrorCircle, BiCheckCircle, BiHelpCircle } from 'react-icons/bi'; // Importing icons
import { Transition } from '@headlessui/react'; // Importing transition from Tailwind UI
import { updateUser } from '../Api/apiService'; // Import API service functions

const UpdateStatus = () => {
  const currentUserEmail = localStorage.getItem('currentUserEmail'); // Access the current authenticated user
  const [status, setStatus] = useState('');
  const [showUpdateButtons, setShowUpdateButtons] = useState(false); // To control whether to show status update buttons
  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the current user's status from localStorage and update the status state
    if (userData) {
      setStatus(userData.userStatus);
    }
  }, [userData]);

  const handleStatusChange = async (newStatus) => {
    let newStatusColor = ''; // Variable to store the new status color
    
    // Determine the new status color based on the new status
    switch (newStatus) {
      case 'SAFE':
        newStatusColor = '#1BBC9B';
        break;
      case 'INJURED':
        newStatusColor = '#F25A7C';
        break;
      case 'HELP':
        newStatusColor = '#FFC107';
        break;
      default:
        newStatusColor = '#1BBC9B';
    }

    try {
      // Update the current user's status and status color in PostgreSQL
      const updatedUserData = {
        ...userData,
        userStatus: newStatus,
        statusColor: newStatusColor,
        userStatusLastUpdated: new Date().toISOString(),
      };
      
      await updateUser(userData.id, updatedUserData);

      // Update the status state locally
      setStatus(newStatus);
      navigate('/home');
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleUpdateDecision = (updateNeeded) => {
    if (updateNeeded) {
      setShowUpdateButtons(true); // Show status update buttons if the user needs to update
    } else {
      navigate('/home'); // Navigate to home if no update is needed
    }
  };

  return (
    <div className=" px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-6">Status Now</h1>
        
        {showUpdateButtons ? (
          <>
            <p className="mb-6 text-lg leading-8 text-gray-600">
              Hi <span className="font-bold text-gray-900">{userData.userFirstName}</span>, please update your status below.
            </p>

            {/* Buttons to update status */}
            <div className="space-y-8 flex flex-col items-center">
              <Transition
                show={true}
                as="button"
                className="btn w-full max-w-xs py-3 px-6 bg-red-600 text-white rounded-md shadow-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => handleStatusChange('INJURED')}
              >
                <BiErrorCircle className="inline-block mr-2 text-xl" />
                I'm INJURED
              </Transition>

              <Transition
                show={true}
                className="btn w-full max-w-xs py-3 px-6 bg-yellow-500 text-white rounded-md  hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                onClick={() => handleStatusChange('HELP')}
              >
                <BiHelpCircle className="inline-block mr-2 text-xl" />
                I need HELP
              </Transition>

              <Transition
                show={true}
                className="btn w-full max-w-xs py-3 px-6 bg-green-600 text-white rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                onClick={() => handleStatusChange('SAFE')}
              >
                <BiCheckCircle className="inline-block mr-2 text-xl" />
                I'm SAFE
              </Transition>
            </div>
          </>
        ) : (
          <>
            <p className="mb-6 text-lg leading-8 text-gray-600">
              Hi <span className="font-bold text-gray-900">{userData.userFirstName}</span>, it looks like you've already checked in. Do you need to update your current status?
            </p>

            {/* Yes and No buttons for update decision */}
            <div className="space-y-8 flex flex-col items-center">
              <Transition
                show={true}
                as="button"
                className="btn w-full max-w-xs py-3 px-6 bg-blue-600 text-white rounded-md shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={() => handleUpdateDecision(true)}
              >
                Yes, I need to update
              </Transition>

              <Transition
                show={true}
                as="button"
                className="btn w-full max-w-xs py-3 px-6 bg-gray-600 text-white rounded-md shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => handleUpdateDecision(false)}
              >
                No, I'm all set
              </Transition>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateStatus;
