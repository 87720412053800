import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../Api/apiService';
import { uploadFileToStorage, getDownloadURL, ref, storage, deleteObject } from '../firebase'; 


export default function ChangeStatusPage() {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userStatus: '',
    address: '',
    phone: '',
    dateOfBirth: '',
    profileImageURL: '',
    statusColor: '',
    title:'',
  });
  const [fullname, setFullname] = useState('');
  const [prevImageURL, setPrevImageURL] = useState('');
  const [localFormData, setLocalFormData] = useState({});
  const navigate = useNavigate();

  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
  const userId = userData?.id;

  const handleGoBack = () => {
    navigate('/home');
  };

  useEffect(() => {
    if (userData) {
      setFormData(userData);
      setFullname(`${userData.userFirstName} ${userData.userLastName}`);
      if (userData.profileImageURL.startsWith("https://firebasestorage.googleapis.com/")) {
        setPrevImageURL(userData.profileImageURL);
      }
      setLocalFormData(userData);
    }
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({
      ...localFormData,
      [name]: value,
    });
  };

  const handleSaveProfile = async () => {
    const updatedUser = {
      id: userId,
      userFirstName: localFormData.userFirstName,
      userLastName: localFormData.userLastName,
      userEmail: localFormData.userEmail,
      title: localFormData.title || '',
      phone: localFormData.phone || '',
      address: localFormData.address || '',
      userStatus: localFormData.userStatus,
      statusColor: localFormData.statusColor,
      profileImageURL: localFormData.profileImageURL,
      managedGroupIDs: localFormData.managedGroupIDs || [],
      joinedGroupIDs: localFormData.joinedGroupIDs || [],
      groupAlert: localFormData.groupAlert || 'Default Alert',
      subscription: localFormData.subscription || 'Default Subscription',
      subscriptionPackage: localFormData.subscriptionPackage || 'Default Package',
      dateSubscribed: localFormData.dateSubscribed || new Date().toISOString(),
      fcmtokens: localFormData.fcmtokens || [],
      userStatusLastUpdated: new Date().toISOString() 
    };

    try {
      console.log('Updated User:', updatedUser);
      localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUser));
      const response = await updateUser(userId, updatedUser);
      console.log('API Response:', response);
      setFormData(updatedUser);
      navigate('/home');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleStatusChange = (newStatus) => {
    let newStatusColor = '';
    switch (newStatus) {
      case 'SAFE':
        newStatusColor = '#1BBC9B';
        break;
      case 'INJURED':
        newStatusColor = '#F25A7C';
        break;
      case 'HELP':
        newStatusColor = '#FFC107';
        break;
      default:
        newStatusColor = '#1BBC9B';
    }

    const updatedFormData = {
      ...localFormData,
      userStatus: newStatus,
      statusColor: newStatusColor,
      userStatusLastUpdated: new Date().toISOString() 
      
    };

    setLocalFormData(updatedFormData);
  };

 

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSaveProfile();
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10 px-4 py-8">
      <form id="profileForm" onSubmit={handleFormSubmit}>
        <div className="gap-x-8 gap-y-8 pt-10">
          <div className="sm:col-span-4">
            <label htmlFor="userStatus" className="block text-sm font-medium leading-6 text-gray-900">Status</label>
            <p className="mt-1 text-sm leading-6 text-gray-600">Update your current status.</p>
            <input
              type="text"
              name="userStatus"
              id="userStatus"
              value={localFormData.userStatus}
              onChange={handleInputChange}
              className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              disabled
            />
          </div>

          <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
              <button
                type="button"
                className="col-span-1 rounded-md bg-red-100 text-red-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-200"
                onClick={() => handleStatusChange('INJURED')}
              >
                I'm INJURED
              </button>
              <button
                type="button"
                className="col-span-1 rounded-md bg-yellow-100 text-yellow-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-yellow-200"
                onClick={() => handleStatusChange('HELP')}
              >
                I need HELP
              </button>
              <button
                type="button"
                className="col-span-1 rounded-md bg-green-100 text-green-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-200"
                onClick={() => handleStatusChange('SAFE')}
              >
                I'm SAFE
              </button>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={handleGoBack}>Cancel</button>
            <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
