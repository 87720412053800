import React, { useState, useEffect } from 'react';

const Navbar = ({ currentLevel = 1, totalSteps = 4 }) => {
  const [activePage, setActivePage] = useState("Overview");

  // Update the active page based on the current location (can use React Router in real apps)
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("Food Supply")) setActivePage("Create Food Supply");
    else if (path.includes("create_evac_plan")) setActivePage("Plan Evacuation");
    else if (path.includes("emergency_contacts")) setActivePage("Emergency Contacts");
    else if (path.includes("Additional_Supplies")) setActivePage("Additional Supplies");
    else setActivePage("Overview");
  }, []);

  return (
    <nav className="navbar recBlu fixed-top">
      <div className="container-fluid">
        <div className="px-3 my-2">
          <img
            src="./images-sitprep/SitPrep Logo White.png"
            width="150"
            alt="SitPrep Logo"
          />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {/* User Level Display */}
          <div className="me-4 text-white">
            <span className="me-2">Level:</span>
            <span className="badge bg-success text-dark">
              {currentLevel}/{totalSteps}
            </span>
          </div>
          <button
            className="navbar-toggler bg-white txtLiteOrg"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon text-white bg-white"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header recBlu">
              <div className="mt-3">
                <img
                  src="./images-sitprep/SitPrep Logo White.png"
                  width="150"
                  alt="SitPrep Logo"
                />
              </div>
              <button
                type="button"
                className="btn-close text-white bg-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body recBlu">
              <p className="fs-4 text-white font-weight-bold pt-4">Emergency Plan</p>
              {[
                { name: "Overview", icon: "dashboard", link: "./dashboard Main.html" },
                { name: "Create Food Supply", icon: "kitchen", link: "./Food Supply.html" },
                { name: "Plan Evacuation", icon: "map", link: "./create_evac_plan.html" },
                { name: "Emergency Contacts", icon: "contact_phone", link: "./emergency_contacts.html" },
                { name: "Additional Supplies", icon: "backpack", link: "./Additional_Supplies.html" },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`my-2 pl-2 d-flex ${
                    activePage === item.name ? "text-white orgOppate rounded" : "txtOppate"
                  }`}
                >
                  <span className={`material-icons pr-3 ${activePage === item.name ? "text-white" : "txtOppate"}`}>
                    {item.icon}
                  </span>
                  <a
                    className={`mt-1 pl-2 ${
                      activePage === item.name ? "text-white" : "txtOppate"
                    } d-flex btn font-weight-bold`}
                    href={item.link}
                    role="tab"
                  >
                    {item.name}
                  </a>
                </div>
              ))}
              <p className="fs-4 txtOppate pt-4">Long Term</p>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">food_bank</span>
                <a
                  href="../assets/storageCalc.html"
                  className="mt-1 nav-link pl-2 txtOppate d-flex"
                >
                  Food Storage
                </a>
              </div>
              <p className="fs-4 txtOppate pt-4">Info</p>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">support_agent</span>
                <a
                  className="mt-1 pl-2 txtOppate d-flex nav-link"
                  href="../assets/sai.html"
                >
                  Ask Sai
                </a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">emoji_emotions</span>
                <a
                  className="mt-1 pl-2 txtOppate d-flex nav-link"
                  href="https://g.page/r/CcCOilHmZderEB0/review"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Was This Tool Helpful?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
