import React, { useState, useEffect } from 'react';
import { fetchAllGroups, fetchGroupById, updateGroup, deleteGroup } from '../../Api/apiService';
import { FaBell, FaStopCircle, FaTrash } from 'react-icons/fa';
import GroupImage from '../UI Comps/GroupImage';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ManageGroupsModal = ({ currentUserEmail, onClose }) => {
  const [adminGroups, setAdminGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const normalizedCurrentUserEmail = currentUserEmail.trim().toLowerCase();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const allGroups = await fetchAllGroups();
        const userAdminGroups = allGroups.filter((group) =>
          group.adminEmails.includes(currentUserEmail)
        );
        setAdminGroups(userAdminGroups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, [currentUserEmail]);

  const handleStartStatus = async (groupId) => {
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Active' };
      await updateGroup(groupId, updatedGroupData);
      setAdminGroups((prev) =>
        prev.map((group) =>
          group.groupId === groupId ? { ...group, alert: 'Active' } : group
        )
      );
    } catch (error) {
      console.error('Error starting status request:', error);
    }
  };

  const handleEndStatus = async (groupId) => {
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Inactive' };
      await updateGroup(groupId, updatedGroupData);
      setAdminGroups((prev) =>
        prev.map((group) =>
          group.groupId === groupId ? { ...group, alert: 'Inactive' } : group
        )
      );
    } catch (error) {
      console.error('Error ending status request:', error);
    }
  };

  const handleDeleteGroup = async () => {
    if (selectedGroup) {
      try {
        await deleteGroup(selectedGroup.groupId);
        setAdminGroups((prev) =>
          prev.filter((group) => group.groupId !== selectedGroup.groupId)
        );
        console.log('Group deleted successfully!');
      } catch (error) {
        console.error('Error deleting group:', error);
      } finally {
        setShowDeletePrompt(false);
      }
    }
  };

  const openDeletePrompt = (group) => {
    setSelectedGroup(group);
    setShowDeletePrompt(true);
  };

  const closeDeletePrompt = () => {
    setShowDeletePrompt(false);
    setSelectedGroup(null);
  };

  if (loading) {
    return (
      <div
        className="modal fade show d-block"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <p>Loading groups...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="modal fade show d-block"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          style={{ maxHeight: '90vh' }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-bold">Manage Groups</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body overflow-auto"
              style={{ maxHeight: '75vh' }}
            >
              {adminGroups.length === 0 ? (
                <p className="text-center">You are not an admin of any groups.</p>
              ) : (
                <ul className="grid grid-cols-1 gap-3 sm:grid-cols-1 lg:grid-cols-2">
                  {adminGroups.map((group) => {
                    const normalizedOwnerEmail = group.ownerEmail.trim().toLowerCase();

                    return (
                      <li
                        key={group.groupId}
                        className="col-span-1 rounded-lg bg-gray-50 border border-gray-300 p-4"
                      >
                        <div className="flex items-center">
                          <GroupImage group={group} />
                          <div className="ml-3 flex-1 ">
                            <h3 className="text-sm font-medium text-gray-900">
                              {group.groupName}
                            </h3>
                            <p className=" text-xs text-gray-500">
                              Code: {group.groupCode}
                            </p>
                          </div>
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          {group.alert === 'Active' ? (
                            <button
                              onClick={() => handleEndStatus(group.groupId)}
                              className="py-1 px-3 text-xs font-semibold text-red-600 border border-red-600 rounded-lg hover:bg-red-100 transition"
                            >
                              End Request
                            </button>
                          ) : (
                            <button
                              onClick={() => handleStartStatus(group.groupId)}
                              className="py-1 px-3 text-xs font-semibold text-green-600 border border-green-600 rounded-lg hover:bg-green-100 transition"
                            >
                              Get Status
                            </button>
                          )}
                          {normalizedOwnerEmail === normalizedCurrentUserEmail && (
                            <button
                              onClick={() => openDeletePrompt(group)}
                              className="py-1 px-3 text-xs font-semibold text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 transition"
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Prompt */}
      <Modal show={showDeletePrompt} onHide={closeDeletePrompt} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the group{' '}
          <strong>{selectedGroup?.groupName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeletePrompt}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteGroup}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageGroupsModal;
