import React, { useEffect, useState, useRef } from 'react';
import { IoMdMore } from 'react-icons/io';
import { fetchAllUserInfo } from '../Api/apiService';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaSms } from 'react-icons/fa';

const IndividualUser = ({ userList }) => {
  const [userListData, setUserListData] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // ✅ Track if the component is still mounted

    const fetchUsers = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        if (isMounted) {
          const usersData = allUserInfo.filter(user => userList.includes(user.userEmail));
          setUserListData(usersData);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();

    // Cleanup function to prevent memory leaks
    return () => {
      isMounted = false; // ✅ Set isMounted to false when component unmounts
    };
  }, [userList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const getBackgroundColor = (user) => {
    const backgroundColors = ['#6366F1', '#9333EA', '#F59E0B', '#EF4444'];
    const initial = user.userFirstName?.charAt(0).toUpperCase() || 'A'; // Fallback to 'A' if firstName is missing
    return backgroundColors[initial.charCodeAt(0) % backgroundColors.length];
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return 'No Recent Updates';
  
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true, 
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(new Date(isoDate));
  };

  return (
    <div className="">
      {userListData.length > 0 ? (
        userListData.map((user) => (
          <div 
            key={user.userEmail} 
            className="bg-white p-2 mb-2 flex items-center justify-between"
          >
            {/* User Avatar and Name */}
            <div className="flex items-center space-x-4">
              <div className="relative">
                {user.profileImageURL ? (
                  <img 
                    src={user.profileImageURL} 
                    alt="User Avatar" 
                    className="w-16 h-16 rounded-full border-2 border-gray-300"
                  />
                ) : (
                  <div 
                    className="w-16 h-16 flex items-center justify-center rounded-full" 
                    style={{ backgroundColor: getBackgroundColor(user) }}
                  >
                    <span className="text-white text-lg font-bold">
                      {`${user.userFirstName?.charAt(0) || ''}${user.userLastName?.charAt(0) || ''}`}
                    </span>
                  </div>
                )}
              </div>

              <div>
                <h3 className="font-semibold text-lg text-gray-800">
                  {user.userFirstName} {user.userLastName}
                </h3>
               
                {/* Status and Status Date */}
                <div className="flex items-center space-x-4 mt-1">
                  <span 
                    className={`badge px-3 py-1 rounded-full text-xs font-medium ${
                      user.userStatus === 'INJURED' ? 'bg-red-100 text-red-700' : 
                      user.userStatus === 'HELP' ? 'bg-yellow-100 text-yellow-700' : 
                      user.userStatus === 'SAFE' ? 'bg-green-100 text-green-700' : 
                      'bg-violet-200 text-gray-700'
                    }`}
                  >
                    {user.userStatus || 'Unknown'}
                  </span>
                  <p className="text-xs text-gray-500">{formatDate(user.userStatusLastUpdated)}</p>
                </div>
              </div>
            </div>

            {/* Options Menu */}
            <div className="relative">
              <button
                onClick={() => setShowOptions(user.userEmail === showOptions ? null : user.userEmail)}
                className="p-2 rounded-full hover:bg-gray-100"
              >
                <IoMdMore size={24} className="text-gray-500" />
              </button>

              {user.userEmail === showOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg"
                >
                  <button
                    className="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => window.open(`tel:${user.phone}`)}
                  >
                    <FaPhoneAlt className="inline mr-2" /> Call {user.userFirstName}
                  </button>

                  <button
                    className="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => window.open(`sms:${user.phone}`)}
                  >
                    <FaSms className="inline mr-2" /> Text {user.userFirstName}
                  </button>

                  <button
                    className="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => window.open(`mailto:${user.userEmail}`)}
                  >
                    <FaEnvelope className="inline mr-2" /> Email {user.userFirstName}
                  </button>

                  <button
                    className="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(user.address)}`)}
                  >
                    <FaMapMarkerAlt className="inline mr-2" /> Get Directions
                  </button>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-gray-500">Loading...</div>
      )}
    </div>
  );
};

export default IndividualUser;
