import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchUserProfileByEmail, fetchGroupById, updateGroup } from '../../Api/apiService';
import { MdEmail, MdCall, MdTextsms } from "react-icons/md";
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import MainAvatar from '../UI Comps/defaultAvatar';

const MapView = ({ groupData }) => {
  // State Variables
  const [userLocations, setUserLocations] = useState([]);
  const [subgroupLocations, setSubgroupLocations] = useState([]);
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState(groupData?.address || '');
  const [updatedLat, setUpdatedLat] = useState(groupData?.latitude);
  const [updatedLng, setUpdatedLng] = useState(groupData?.longitude);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [mapCenter, setMapCenter] = useState([groupData?.latitude || 37.0902, groupData?.longitude || -95.7129]);
  const [filterStatus, setFilterStatus] = useState('ALL');
  const [currentTile, setCurrentTile] = useState('esri');
  const autocompleteRef = useRef(null);

  // Toggle tile layer between Carto and Esri
  const toggleTileLayer = () => {
    setCurrentTile((prevTile) => (prevTile === 'esri' ? 'carto' : 'esri'));
  };

  // Fetch user and subgroup data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const memberEmails = groupData.memberEmails || [];
        const userProfiles = await Promise.all(memberEmails.map(email => fetchUserProfileByEmail(email)));
        setUserLocations(
          userProfiles
            .filter(user => user.latitude && user.longitude)
            .map(user => ({
              latitude: parseFloat(user.latitude),
              longitude: parseFloat(user.longitude),
              userFirstName: user.userFirstName,
              userLastName: user.userLastName,
              statusColor: user.statusColor,
              userStatus: user.userStatus,
              profileImageURL: user.profileImageURL,
              address: user.address,
              phone: user.phone,
              userEmail: user.userEmail,
            }))
        );
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchSubgroupData = async () => {
      try {
        if (!Array.isArray(groupData.subGroupIDs) || groupData.subGroupIDs.length === 0) {
          console.warn('No subgroups to fetch.');
          setSubgroupLocations([]);
          return;
        }

        const statusPriority = { "NO RESPONSE": 1, "INJURED": 2, "HELP": 3, "SAFE": 4 };
        const statusColors = {
          'NO RESPONSE': '#99738E',
          'INJURED': '#F25A7C',
          'HELP': '#FFC107',
          'SAFE': '#1BBC9B',
        };

        const subgroupData = await Promise.all(groupData.subGroupIDs.map(id => fetchGroupById(id)));
        const subgroupsWithMemberInfo = await Promise.all(
          subgroupData.map(async (subgroup) => {
            if (subgroup && Array.isArray(subgroup.memberEmails)) {
              const members = await Promise.all(subgroup.memberEmails.map(email => fetchUserProfileByEmail(email)));

              const sortedMembers = members
                .map(member => ({
                  userFirstName: member.userFirstName,
                  userLastName: member.userLastName,
                  latitude: member.latitude,
                  longitude: member.longitude,
                  userStatus: member.userStatus,
                  statusColor: statusColors[member.userStatus] || '#99738E',
                  profileImageURL: member.profileImageURL,
                  address: member.address,
                }))
                .sort((a, b) => {
                  const priorityDiff = statusPriority[a.userStatus] - statusPriority[b.userStatus];
                  return priorityDiff !== 0 ? priorityDiff : a.userFirstName.localeCompare(b.userFirstName);
                });

              const highestPriorityColor = statusColors[sortedMembers[0]?.userStatus] || "gray";
              let ownerPhone = null;
              if (subgroup.ownerEmail) {
                const ownerProfile = await fetchUserProfileByEmail(subgroup.ownerEmail);
                ownerPhone = ownerProfile?.phone || 'Phone number not available';
              }

              return {
                ...subgroup,
                members: sortedMembers,
                highestPriorityColor,
                ownerPhone,
              };
            }
            return subgroup;
          })
        );

        setSubgroupLocations(
          subgroupsWithMemberInfo
            .filter(subgroup => subgroup && subgroup.latitude && subgroup.longitude)
            .map(subgroup => ({
              latitude: parseFloat(subgroup.latitude),
              longitude: parseFloat(subgroup.longitude),
              groupName: subgroup.groupName,
              address: subgroup.address,
              ownerName: subgroup.ownerName,
              ownerEmail: subgroup.ownerEmail,
              ownerPhone: subgroup.ownerPhone,
              members: subgroup.members || [],
              highestPriorityColor: subgroup.highestPriorityColor,
              groupType: subgroup.groupType,
            }))
        );
      } catch (error) {
        console.error('Error fetching subgroup data:', error);
      }
    };

    if (groupData) {
      fetchUserData();
      fetchSubgroupData();
    }
  }, [groupData]);

  // Initialize Google Places autocomplete
  useEffect(() => {
    if (window.google && window.google.maps) {
      const addressInput = document.getElementById('addressInput');
      if (addressInput) {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(addressInput);
        autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
      }
    }
  }, [isAddressFormVisible]);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      setUpdatedAddress(place.formatted_address);
      setUpdatedLat(place.geometry.location.lat());
      setUpdatedLng(place.geometry.location.lng());
    }
  };

  const toggleAddressForm = () => {
    setIsAddressFormVisible(!isAddressFormVisible);
  };

  const handleSaveAddress = async () => {
    try {
      if (!groupData?.groupId) {
        console.error("groupId is missing or undefined in groupData.");
        return;
      }

      const updatedGroupData = {
        ...groupData,
        address: updatedAddress,
        latitude: updatedLat,
        longitude: updatedLng,
      };

      await updateGroup(groupData.groupId, updatedGroupData);
      setShowSaveConfirmation(true);
      setTimeout(() => setShowSaveConfirmation(false), 3000);
      setIsAddressFormVisible(false);
      setMapCenter([updatedLat, updatedLng]);
      console.log("Address updated successfully.");
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  const statusColors = {
    'NO RESPONSE': '#99738E',
    'INJURED': '#F25A7C',
    'HELP': '#FFC107',
    'SAFE': '#1BBC9B',
  };

  const createIndividualIcon = (user) => {
    const fillColor = statusColors[user.userStatus] || 'default-color';
    return L.divIcon({
      className: 'custom-individual-pin',
      html: `<svg width="50" height="50" viewBox="0 0 24 32" xmlns="http://www.w3.org/2000/svg">
              <path fill="${fillColor}" d="M12 0C7.03 0 3 4.03 3 9c0 3.78 1.94 8.62 5.84 14.53a3.5 3.5 0 0 0 5.32 0C19.06 17.62 21 12.78 21 9c0-4.97-4.03-9-9-9z"/>
              <g transform="translate(12, 9) scale(0.65) translate(-12, -9)">
                <path d="M12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 10c-4.42 0-8 2.24-8 5v3h16v-3c0-2.76-3.58-5-8-5z" fill="white"/>
              </g>
            </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 50],
      popupAnchor: [0, -50],
    });
  };

  const createSubgroupIcon = (color, groupType) => {
    const iconPaths = {
      "Household": `<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="white"/>`,
      "Social/Groups": `<path d="M12 2a4 4 0 110 8 4 4 0 010-8zm-6 8a3 3 0 110 6 3 3 0 010-6zm12 0a3 3 0 110 6 3 3 0 010-6zM2 20v-2a4 4 0 014-4h12a4 4 0 014 4v2H2z" fill="white"/>`,
      "School Staff/Classroom": `<path d="M12 2l10 5-10 5-10-5 10-5zm0 7l6 3v4l-6 3-6-3v-4l6-3z" fill="white"/>`,
      "Business": `<path d="M4 22h16v-2H4v2zm2-4h12v-2H6v2zm0-4h12v-2H6v2zm0-4h12V8H6v2zm0-4h12V4H6v2z" fill="white"/>`,
      "Other": `<path d="M4 22h16v-2H4v2zm2-4h12v-2H6v2zm0-4h12v-2H6v2zm0-4h12V8H6v2zm0-4h12V4H6v2z" fill="white"/>`,
    };
    const iconPath = iconPaths[groupType];
  
    return L.divIcon({
      className: 'custom-subgroup-pin',
      html: `<svg width="50" height="50" viewBox="0 0 24 32" xmlns="http://www.w3.org/2000/svg">
              <path fill="${color}" d="M12 0C7.03 0 3 4.03 3 9c0 3.78 1.94 8.62 5.84 14.53a3.5 3.5 0 0 0 5.32 0C19.06 17.62 21 12.78 21 9c0-4.97-4.03-9-9-9z"/>
              <g transform="translate(12, 9) scale(0.65) translate(-12, -9)">${iconPath}</g>
            </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 50],
      popupAnchor: [0, -50],
    });
  };

  return (
    <div>
     {/* Feedback or Address Form Section */}
     <div className="flex flex-col items-center text-center space-y-4 p-6 bg-gray-50 rounded-lg shadow-md transition-opacity duration-500">
  <p className="text-gray-700 text-sm">
    For the best experience, encourage members to add their address to their profile or group.
  </p>
  {(isAddressFormVisible || !groupData.latitude || !groupData.longitude) ? (
    isAddressFormVisible ? (
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md transition-opacity duration-500 ease-in-out">
        <div className="px-6 py-5">
          <h3 className="text-xl font-semibold text-gray-900">Enter Verified Address or City</h3>
          <p className="mt-2 text-sm text-gray-600">
            Add a verified address to help locate the group accurately.
          </p>
          <form className="mt-4">
            <div className="w-full">
              <label htmlFor="addressInput" className="sr-only">Address</label>
              <input
                type="text"
                id="addressInput"
                className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter group address or city"
                value={updatedAddress}
                onChange={(e) => setUpdatedAddress(e.target.value)}
              />
            </div>
            <div className="mt-4 flex justify-end space-x-3">
              <button
                type="button"
                className="px-4 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={toggleAddressForm}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-500"
                onClick={handleSaveAddress}
              >
                Save Address
              </button>
            </div>
          </form>
        </div>
      </div>
    ) : (
      (!updatedLat || !updatedLng) && (
        <div className="text-red-600 text-sm transition-opacity duration-500 ease-in-out">
          <p>The group location is missing or invalid. Please add a verified address.</p>
          <button
            onClick={toggleAddressForm}
            className="mt-3 bg-indigo-600 text-white rounded-full px-4 py-2 text-sm hover:bg-indigo-500"
          >
            Add a Verified Address
          </button>
        </div>
      )
    )
  ) : null}
</div>

{/* Save Confirmation Message */}
{showSaveConfirmation && (
  <div className="text-green-600 text-sm text-center mt-4 transition-opacity duration-500 ease-out">
    Address saved successfully!
  </div>
)}


              {/* Map Container */}
           <MapContainer
          center={mapCenter}
          zoom={14}
          style={{ height: "1000px", width: "100%" }}
           zoomControl={true} // Disable default zoom control
          key={mapCenter.toString()}  // Forces re-render on center update
        >
          
          {/* Tile Layer */}
          {currentTile === 'esri' ? (
          
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='&copy; Esri contributors'
            />
          ) : (
            <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; OpenStreetMap contributors &copy; CARTO'
          />
          )}
               


                
                        {/* Filtered User Markers */}
                        {userLocations
                          .filter(user => filterStatus === 'ALL' || user.userStatus === filterStatus)
                          .map((user, index) => (
                            <Marker
                              key={`user-${index}`}
                              position={[user.latitude, user.longitude]}
                              icon={createIndividualIcon(user)}
                            >
                              <Popup>
                                {/* User Header */}
                                <div className="flex items-center ">
                                  <MainAvatar userProfile={user}/>
                                
                                  <div className="flex-1">
                                    <h3 className="text-sm font-medium text-gray-900">
                                      {user.userFirstName} {user.userLastName}
                                    </h3>
                                    <span
                                      className={`inline-block mt-1 px-2 py-0.5 rounded-full text-xs ${
                                        user.userStatus === 'NO RESPONSE' ? 'bg-purple-100 text-purple-700' :
                                        user.userStatus === 'INJURED' ? 'bg-red-100 text-red-700' :
                                        user.userStatus === 'HELP' ? 'bg-yellow-100 text-yellow-700' :
                                        user.userStatus === 'SAFE' ? 'bg-green-100 text-green-700' :
                                        'bg-gray-100 text-gray-700'
                                      }`}
                                    >
                                      {user.userStatus || 'Status not available'}
                                    </span>
                                  </div>
                                </div>

                                {/* User Address */}
                                <p className="mt-2 text-sm">
                                  {user.address ? (
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(user.address)}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-gray-500 hover:underline"
                                    >
                                      {user.address}
                                    </a>
                                  ) : (
                                    'Address not available'
                                  )}
                                </p>

                                {/* Contact Buttons */}
                                <div className="space-x-3 mt-4 flex border-t border-gray-200 pt-2">
                                  <div className="flex-1">
                                    <a
                                      href={`mailto:${user.userEmail}`}
                                      className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium text-gray-100 hover:bg-gray-100"
                                    >
                                      <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                      Email
                                    </a>
                                  </div>
                                  {user.phone && (
                                    <>
                                      <div className="flex-1">
                                        <a
                                          href={`tel:${user.phone}`}
                                          className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium hover:bg-gray-100"
                                        >
                                          <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                          Call
                                        </a>
                                      </div>
                                      <div className="flex-1">
                                        <a
                                          href={`sms:${user.phone}`}
                                          className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium hover:bg-gray-100 "
                                        >
                                          <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                          Text
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Popup>
                            </Marker>
                          ))}

                        {/* Filtered Subgroup Markers */}
                        {subgroupLocations
                          .filter(subgroup => filterStatus === 'ALL' || subgroup.highestPriorityColor === statusColors[filterStatus])
                          .map((subgroup, index) => (
                            <Marker
                              key={`subgroup-${index}`}
                              position={[subgroup.latitude, subgroup.longitude]}
                              icon={createSubgroupIcon(subgroup.highestPriorityColor, subgroup.groupType)}
                            >
                              <Popup>
                                {/* Subgroup Information */}
                                <div className="popup-group-header ">
                                  <p className="mb-1 text-lg font-bold">{subgroup.groupName}</p>
                                  <p>
                                    <strong>Address:</strong>{" "}
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(subgroup.address)}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {subgroup.address}
                                    </a>
                                  </p>
                                  <p className="text-xs"><strong>Contact Owner:</strong> {subgroup.ownerName}</p>

                                  {/* Contact Owner Buttons */}
                                  <div className="pb-3 flex divide-gray-200 border-b space-x-3">
                                    <div className="flex-1">
                                      <a
                                        href={`mailto:${subgroup.ownerEmail}`}
                                        className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium text-gray-900 hover:bg-gray-100"
                                      >
                                        <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                        Email
                                      </a>
                                    </div>
                                    {subgroup.ownerPhone && (
                                      <>
                                        <div className="flex-1">
                                          <a
                                            href={`tel:${subgroup.ownerPhone}`}
                                            className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium hover:bg-gray-100"
                                          >
                                            <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                            Call
                                          </a>
                                        </div>
                                        <div className="flex-1">
                                          <a
                                            href={`sms:${subgroup.ownerPhone}`}
                                            className="btn bg-gray-200 link-secondary flex items-center justify-center w-full py-2 text-sm font-medium hover:bg-gray-100"
                                          >
                                            <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                                            Text
                                          </a>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>

                                {/* Subgroup Members List */}
                                <div className="max-h-60 overflow-y-auto scrollbar-hide">
                                  {subgroup.members.map((user, userIndex) => (
                                    <div key={userIndex} className="flex items-center mt-2">
                                      {user.profileImageURL ? (
                                        <img
                                          src={user.profileImageURL}
                                          alt="User Avatar"
                                          className="rounded-full mr-2"
                                          style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                                        />
                                      ) : (
                                        <span
                                          className="rounded-full bg-black text-white flex items-center justify-center mr-2"
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            fontSize: '10px',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {`${user.userFirstName?.charAt(0) || ''}${user.userLastName?.charAt(0) || ''}`}
                                        </span>
                                      )}
                                      <p className="text-sm m-0 mr-2">
                                        <strong>{user.userFirstName} {user.userLastName}</strong>
                                      </p>
                                      <span
                                        className="badge rounded-full text-xs px-2 py-1 ml-auto"
                                        style={{
                                          backgroundColor:
                                            user.userStatus === 'INJURED' ? '#F25A7C' :
                                            user.userStatus === 'HELP' ? '#FFC107' :
                                            user.userStatus === 'NO RESPONSE' ? '#99738E' :
                                            user.userStatus === 'SAFE' ? '#1BBC9B' :
                                            '#e0e0e0',
                                          color: user.userStatus === 'HELP' ? '#212529' : '#fff',
                                        }}
                                      >
                                        {user.userStatus || 'Status not available'}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </Popup>
                            </Marker>
                          ))}
            </MapContainer>
                      {/* Status Filter and Map Controls */}
            <div
                            className=" bottom-5 flex space-x-5 p-2 fixed mb-3 flex-nowrap overflow-x-auto scrollbar-hide w-100"
                            style={{ zIndex: 1000 }}
                          >
                            {['ALL', 'SAFE', 'HELP', 'INJURED', 'NO RESPONSE'].map((status) => (
                              <button
                                key={status}
                                className={`btn badge px-4 py-2 rounded-full text-xs font-semibold bg-gray-100 text-gray-600 hover:bg-gray-200 ${
                                  status === 'NO RESPONSE'
                                    ? 'custom-bg-purple'
                                    : status === 'INJURED'
                                    ? 'custom-bg-red'
                                    : status === 'HELP'
                                    ? 'custom-bg-yellow'
                                    : status === 'SAFE'
                                    ? 'custom-bg-green'
                                    : 'custom-bg-all'
                                }`}
                                onClick={() => setFilterStatus(status)}
                              >
                                {status}
                              </button>
                            ))}
            </div>

      <div
        className="fixed bottom-10 right-4 p-2 mb-5 scrollbar-hide"
        style={{ zIndex: 2000 }}
      >
        <button
          className="mb-3 border border-black-500 badge px-4 py-2 rounded-full text-xs font-semibold bg-white text-gray-600 hover:bg-gray-200"
          onClick={toggleTileLayer}
        >
          Map View
        </button>
      </div>

    </div>
  );
};

export default MapView;