import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createGroup, updateUser, fetchAllGroups } from '../../Api/apiService';
import InstallPrompt from '../InstallPrompt ';

const CreateGroup = ({ deferredPrompt }) => {
  const currentUser = localStorage.getItem('currentUserEmail');
  const userdata = JSON.parse(localStorage.getItem(`userData-${currentUser}`));

  const [groupType, setGroupType] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [privacy, setPrivacy] = useState('Public');
  const [description, setDescription] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isInstallPromptOpen, setIsInstallPromptOpen] = useState(false);
  const [groupNameError, setGroupNameError] = useState('');
  const [groupCodeError, setGroupCodeError] = useState('');
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [loadingError, setLoadingError] = useState('');
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);

  const debounceTimeout = 500;
  let debounceTimer;

  const checkGroupNameAndCode = useCallback(
    async (field, value) => {
      try {
        const allGroups = await fetchAllGroups();
        const isNameTaken = field === 'groupName' && allGroups.some((group) => group.groupName.toLowerCase() === value.toLowerCase());
        const isCodeTaken = field === 'groupCode' && allGroups.some((group) => group.groupCode.toLowerCase() === value.toLowerCase());

        setGroupNameError(isNameTaken ? 'Group name is already in use.' : '');
        setGroupCodeError(isCodeTaken ? 'Group code is already in use.' : '');
      } catch (error) {
        console.error('Error checking group name and code:', error);
        setError('Failed to validate group details.');
      }
    },
    []
  );

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    field === 'groupName' ? setGroupName(value) : setGroupCode(value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      checkGroupNameAndCode(field, value);
    }, debounceTimeout);
  };

  const groupTypes = [
    'Business', 'Church', 'Extended Family', 'Friends', 'HOA/Neighborhood', 'Household', 
    'Hospital or Physician Office', 'Religious Organization', 'Social/Groups', 'School Staff/Classroom', 'Other',
  ];

  useEffect(() => {
    const initializeAutocomplete = () => {
      try {
        const addressInput = document.getElementById('address');
        if (addressInput && window.google && window.google.maps) {
          autocompleteRef.current = new window.google.maps.places.Autocomplete(addressInput);
          autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
        } else {
          throw new Error('Address input element not found or Google Maps not loaded');
        }
      } catch (error) {
        console.error('Google Maps API failed to load:', error);
        setLoadingError('Failed to load Google Maps. Please try again later.');
      }
    };

    // Retry initialization until Google Maps and the address input are both available
    const intervalId = setInterval(() => {
      if (window.google && document.getElementById('address')) {
        initializeAutocomplete();
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
        const lat = place.geometry.location.lat().toString();
        const lng = place.geometry.location.lng().toString();
        setAddress(place.formatted_address);
        setLatitude(lat);
        setLongitude(lng);
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!groupType || !groupName || !groupCode) {
      setError('Please fill in all fields.');
      return;
    }

    if (!userdata) {
      setError('User data not found. Please make sure you are logged in.');
      return;
    }

    const isUnique = !(groupNameError || groupCodeError);
    if (!isUnique) return;

    const newGroup = {
      groupName, adminEmails: [userdata.userEmail], alert: 'Not Active', description, groupType,
      lastUpdatedBy: userdata.userEmail, memberCount: 1, memberEmails: [userdata.userEmail],
      privacy, address, latitude, longitude, subGroupIDs: [], createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(), zipCode, ownerName: `${userdata.userFirstName} ${userdata.userLastName}`,
      ownerEmail: userdata.userEmail, groupCode,
    };

    try {
      const createdGroup = await createGroup(newGroup);
      if (createdGroup.groupId) {
        const updatedUser = { ...userdata, managedGroupIDs: [...userdata.managedGroupIDs, createdGroup.groupId] };
        await updateUser(userdata.id, updatedUser);

        localStorage.setItem(`userData-${currentUser}`, JSON.stringify(updatedUser));
        setGroupName(''); setGroupType(''); setGroupCode(''); setAddress(''); setPrivacy('Public');
        setDescription(''); setZipCode(''); setLatitude(null); setLongitude(null);
        setError(''); setSuccess('Group created successfully!');
        navigate('/');

        if (!window.matchMedia('(display-mode: standalone)').matches && !window.navigator.standalone) {
          setIsInstallPromptOpen(true);
        } else {
          navigate('/');
        }
      } else {
        setError('An error occurred while creating the group.');
      }
    } catch (error) {
      console.error('Error creating group:', error);
      setError('An error occurred while creating the group.');
    }
  };

  const handleCloseInstallPrompt = () => {
    setIsInstallPromptOpen(false);
    navigate('/');
  };

  useEffect(() => {
    const isFormComplete = groupType && groupName && groupCode && !groupNameError && !groupCodeError;
    setIsCreateDisabled(!isFormComplete);
  }, [groupType, groupName, groupCode, groupNameError, groupCodeError]);

  return (
    <div className="mt-5 pt-5 bg-white container mx-auto px-6">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">Create a Group</h2>
      <p className="mt-1 text-sm text-gray-600">
        Please fill in the details below to create your group. Fields marked with an asterisk (*) are required.
      </p>

      {loadingError && <p className="text-red-600">{loadingError}</p>}

      <form onSubmit={handleSubmit} className="mt-6 space-y-6">
        {error && <p className="text-red-600">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}

        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="groupType" className="block text-sm font-medium leading-6 text-gray-900">
                  Group Type 
                </label>
                <div className="mt-2">
                  <select
                    className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                    id="groupType"
                    value={groupType}
                    onChange={(e) => setGroupType(e.target.value)}
                    required
                  >
                    <option value="" disabled>Select Group Type</option>
                    {groupTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
              </div>

              {groupType && (
                <>
                  <div className="sm:col-span-4">
                    <label htmlFor="groupName" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Name 
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3  focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="groupName"
                        value={groupName}
                        onChange={(e) => handleInputChange(e, 'groupName')}
                        placeholder="Enter the name of your group"
                        required
                      />
                      {groupNameError && <p className="text-red-600">{groupNameError}</p>}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="groupCode" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Code 
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="groupCode"
                        value={groupCode}
                        onChange={(e) => handleInputChange(e, 'groupCode')}
                        placeholder="Enter a code that users can use to search for your group (e.g., 'ABCGroup')"
                        required
                      />
                      {groupCodeError && <p className="text-red-600">{groupCodeError}</p>}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="privacy" className="block text-sm font-medium leading-6 text-gray-900">
                      Privacy 
                    </label>
                    <div className="mt-2">
                      <select
                        className={`block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm ${
                          groupType === 'Household' ? 'text-gray-500' : 'text-gray-900'
                        }`}
                        id="privacy"
                        value={groupType === 'Household' ? "Private" : privacy}
                        onChange={(e) => setPrivacy(e.target.value)}
                        disabled={groupType === 'Household'}
                        required
                      >
                        <option value="Public">Public Group</option>
                        <option value="Private">Private Group</option>
                      </select>
                    </div>
                  </div>

                
                    <div className="sm:col-span-4">
                      <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                        Address (Optional, but recommended)
                      </label>
                      <div className="mt-2">
                      <input
                        type="text"
                        id="address"
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter your address"
                      />
                      </div>
                      <p className='text-xs text-gray-500'>
                        Status Now uses your address only as you allow. Sharing it with a group admin helps them know the best way to reach your group in emergencies.
                        Your privacy is always prioritized.
                      </p>
                    </div>
                

                 
                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Purpose
                    </label>
                    <div className="mt-2">
                      <textarea
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="description"
                        rows="3"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter a brief description of your group..."
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pb-5 mt-6 flex items-center justify-end gap-x-6">
          <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </Link>
          <button
            type="submit"
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white ${
              isCreateDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500'
            } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            disabled={isCreateDisabled}
          >
            Create Group
          </button>
        </div>
      </form>

      <InstallPrompt
        deferredPrompt={deferredPrompt}
        isOpen={isInstallPromptOpen}
        onClose={handleCloseInstallPrompt}
      />
    </div>
  );
};

export default CreateGroup;
