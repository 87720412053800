import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { CgAddR } from "react-icons/cg";

const AppendGroup = ({ groupData, currentUserEmail }) => {
  // Get user data from localStorage
  const user = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));

  // Function to get the image path based on group type
  const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business': return '/images/Business.svg';
      case 'Church': return '/images/Hospital.svg';
      case 'Extended Family': return '/images/Family.svg';
      case 'Friends': return '/images/Friend.svg';
      default: return '/images/Neighbor.svg'; // Default image if group type is not recognized
    }
  };

  // If no groups exist, prompt the user to create their first group
  if (!groupData || groupData.length === 0) {
    return (
      <div className="text-center text-gray-800">
        <div className='p-4'>
          <p className='font-bold h4'>Create Your First Group</p>
          <p>Welcome to Status Now, <span className='font-bold'>{user?.userFirstName}</span>! 👋</p>
          <p className='mt-2'>
            To get started, tap the <span className='text-success font-bold'>green</span> icon below to create your first group and invite the people who matter most to join you.
          </p>
          <Link className='rounded-pill btn text-success mt-4 text-5xl' to={'/create-group'}>
            <CgAddR />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      {groupData.map((group) => (
        <ul key={group.groupID}>
          <Link to={`/linked/${group.groupId}`} className='mb-5'>
            <li className="relative flex justify-between gap-x-6 p-3 hover:bg-gray-50 sm:px-6 border-top border-light">
              <div className="flex min-w-0 gap-x-4">
                <img
                  src={getImagePath(group.groupType)} 
                  alt={group.groupType}
                  style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-md font-medium leading-6 text-gray-800">{group.groupName}</p>
                  <p className="mt-1 flex text-xs font-medium leading-5 text-gray-500">Code: {group.groupCode}</p>
                </div>
              </div>

              {/* Dynamic rendering based on group.alert and pendingMemberEmails */}
              <div className="flex shrink-0 items-center gap-x-4">
                {group.alert === 'Active' && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="5" fill="none" stroke="#FF4E00" strokeWidth="3">
                      <animate attributeName="r" values="5;25;5" dur="2s" repeatCount="indefinite" />
                      <animate attributeName="opacity" values="1;0;1" dur="2s" repeatCount="indefinite" />
                    </circle>
                    <circle cx="50" cy="50" r="5" fill="none" stroke="#FF4E00" strokeWidth="3">
                      <animate attributeName="r" values="15;35;15" dur="2s" repeatCount="indefinite" begin="0.5s" />
                      <animate attributeName="opacity" values="1;0;1" dur="2s" repeatCount="indefinite" begin="0.5s" />
                    </circle>
                    <circle cx="50" cy="50" r="5" fill="none" stroke="#FF4E00" strokeWidth="3">
                      <animate attributeName="r" values="25;45;25" dur="2s" repeatCount="indefinite" begin="1s" />
                      <animate attributeName="opacity" values="1;0;1" dur="2s" repeatCount="indefinite" begin="1s" />
                    </circle>
                  </svg>
                )}

                {group.pendingMemberEmails.length > 0 && (
                  <div className="relative inline-flex">
                    <UserCircleIcon className="h-7 w-7 text-gray-500" />
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full transform translate-x-1/2 -translate-y-1/2">
                      {group.pendingMemberEmails.length}
                    </span>
                  </div>
                )}

                <ChevronRightIcon className="h-7 w-7 flex-none text-gray-400" aria-hidden="true" />
              </div>
            </li>
          </Link>
        </ul>
      ))}
    </div>
  );
};

export default AppendGroup;
