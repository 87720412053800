import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Routes, Route, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { requestForToken, messaging } from './firebase';
import { fetchUserByEmail, updateUser } from './Api/apiService';
import { onMessage } from 'firebase/messaging';

import HomePage from './pages/HomePage';
import MyGroup from './pages/MyGroup';
import GetStatus from './pages/GetStatus';
import NotFoundPage from './pages/NotFoundPage';
import Login from './components/login';
import Register from './pages/createUser';
import LandingPage from './pages/landingPage';
import CreateGroup from './components/Group Comps/createGroup.js';
import ChangeStatusPage from './pages/StatusChangePage.js';
import Profile from './pages/profilePage.js';
import JoinGroup from './pages/JoinGroupPage.js';
import MemberGroup from './pages/memberGroup.js';
import ManageGroup from './components/Group Comps/ManageGroup.js';
import NotificationsPage from './pages/NotificationsPage.js';
import UpdateStatusPage from './pages/StatusUpdate.js';
import EmbeddedSite from './components/sitprep.js';
import SitPrepHome from './components/components-sitprep/sitprep-pages/SitPrepHome.js';
import EmergencyContacts from './components/components-sitprep/sitprep-pages/emergency-contatcs.js';
import TermsAndPrivacy from './pages/termsandprivacy.js';
import MapPage from './pages/mapPage.js';
import StatusReadyPricing from './pages/subscriptionPlans.js';
import EmergencyAssessment from './components/components-sitprep/sitprep-pages/sitprepQuiz.js';
import JoinPrivateGroup from './components/JoinPrivateGroup.js';


function AppAuth({ deferredPrompt }) {
  const { currentUser } = useAuth();
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Function to enable notifications
    const handleEnableNotifications = async () => {
      const permission2 = await Notification.requestPermission();
      const permission = await window.Notification.requestPermission();
      if (permission === 'granted' || permission2 === 'granted') {
        console.log('Notification permission granted.');

        // Update the FCM token in the user profile
        await getToken();

        // Optionally, refresh the page after permission change
        window.location.reload();
      } else {
        console.error('Notification permission denied.');
      }
    };

    // Function to get the FCM token and update the user profile
    const getToken = async () => {
      if (currentUser) {
        try {
          const currentToken = await requestForToken(); // Get the FCM token
          const userProfile = await fetchUserByEmail(currentUser.email); // Fetch user profile by email
          if (!userProfile.fcmtoken && currentToken) {
            // Update the user with the new token
            await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
            console.log('FCM token updated for user:', userProfile.email);
          }
        } catch (error) {
          console.error('Error refreshing token or updating user:', error);
        }
      }
    };

  

    // Handle foreground messages
    const handleForegroundMessages = () => {
      onMessage(messaging, (payload) => {
        console.log('Received foreground message: ', payload);

        const { title, body, icon, badge, type, groupId } = payload.data;

        const notificationTitle = title || 'Default Title';
        const notificationOptions = {
          body: body || 'Default Body',
          icon: icon || '/images/icon-120.png',
          badge: badge || '/images/icon-512.png',
          requireInteraction: true,
          sound: 'default',
          data: { type, groupId }, // Add type and groupId to the notification data
        };

        const notification = new Notification(notificationTitle, notificationOptions);

        notification.onclick = (event) => {
          event.preventDefault(); // Prevent the browser from focusing the Notification's tab
          let url = '/';

          // Determine the URL to navigate based on the notification type
          if (type === 'alert') {
            url = '/status-now';
          } else if (type === 'new_member') {
            url = `/Linked/${groupId}`;
          } else if (type === 'post_notification') {
            url = `/Linked/${groupId}`;
          }

          window.open(url, '_blank');
        };
      });
    };

    // Initialize user
    const initializeUser = () => {
      if (currentUser) {
        const currentUserEmailFromStorage = localStorage.getItem('currentUserEmail');
        if (!currentUserEmailFromStorage || currentUserEmailFromStorage !== currentUser.email) {
          const joingroupId = localStorage.getItem('joingroupId');
          localStorage.clear();
          if (joingroupId) {
            localStorage.setItem('joingroupId', joingroupId);
          }
          localStorage.setItem('currentUserEmail', currentUser.email);
        }
        setCurrentUserEmail(currentUser.email);
      }
    };

    // Function to check and request notification permission
    const checkNotificationPermission = async () => {
      // If permission is not granted or denied, request permission
      if (Notification.permission === 'default') {
        handleEnableNotifications();
      }
    };

    // Initialize user and check for notifications
    checkNotificationPermission();
    handleForegroundMessages(); // Check and request notification permission if needed
    initializeUser();
    getToken();
    // refreshToken(); // Refresh token on initial load
    // getTokenEmpty();
  
   

    // // Set up an interval to refresh the token every hour
    // const interval = setInterval(() => {
    //   refreshToken(); // Refresh token every hour
    // }, 3600000); // 3600000ms = 1 hour

    // return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentUser]);


  return (
    <div className="App bg-light bg-home">
      <section className="font-sans ">
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          
          {/* Landing (Login) Page */}
          <Route path="/landing" element={currentUser ? <Navigate to="/home" /> : <LandingPage />} />
          
          {/* Authenticated Routes */}
          <Route path="/profile" element={currentUser ? <Profile currentUserEmail={currentUserEmail} /> : <Navigate to="/landing" />} />
          
          <Route path="/notifications" element={currentUser ? <NotificationsPage currentUserEmail={currentUserEmail} /> : <Navigate to="/landing" />} />
          
          <Route path="/home" element={currentUser ? <HomePage currentUserEmail={currentUserEmail} deferredPrompt={deferredPrompt} /> : <Navigate to="/landing" />} />
          
          <Route path="/sitprep" element={currentUser ? <SitPrepHome currentUserEmail={currentUserEmail} /> : <Navigate to="/landing" />} />
          
          <Route path="/Linked/:groupId" element={currentUser ? <MemberGroup /> : <Navigate to="/landing" />} />
          
          <Route path="/Group/:groupId" element={currentUser ? <MyGroup /> : <Navigate to="/landing" />} />
          
          <Route path="/groups/:groupId/edit" element={currentUser ? <ManageGroup /> : <Navigate to="/landing" />} />
          
          <Route path="/Status/:groupId" element={currentUser ? <GetStatus /> : <Navigate to="/landing" />} />
          
          <Route path="/create-group" element={currentUser ? <CreateGroup deferredPrompt={deferredPrompt} /> : <Navigate to="/landing" />} />
          
          <Route path="/status-now" element={currentUser ? <UpdateStatusPage /> : <Navigate to="/landing" />} />

          <Route path="/status-map/:groupId" element={currentUser ? <MapPage/> : <Navigate to="/landing" />} />
          
          <Route path="/change-status" element={currentUser ? <ChangeStatusPage /> : <Navigate to="/landing" />} />
          
          <Route path="/emergency-contacts" element={currentUser ? <EmergencyContacts /> : <Navigate to="/landing" />} />
          <Route path="/join-invite" element={currentUser ? <JoinPrivateGroup deferredPrompt={deferredPrompt} /> : <Navigate to="/landing" />} />

          <Route path="/subscriptions" element={currentUser ? <StatusReadyPricing /> : <Navigate to="/landing" />} />
          
          {/* Open Routes */}
          <Route path="/joingroup" element={<JoinGroup />} />
          <Route path="/sitprep-quiz" element={<EmergencyAssessment />} /> 
          <Route path="/terms-privacy" element={<TermsAndPrivacy />} /> 
          
          <Route path="/embedded-site" element={<EmbeddedSite />} />
          
          {/* Login and Register Routes */}
          <Route path="/login" element={currentUser ? <Navigate to="/home" /> : <Login />} />
          
          <Route path="/create-account" element={currentUser ? <Navigate to="/home" /> : <Register />} />
          
          {/* Catch-all for Not Found */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </section>
    </div>
  );
  
}
function App({deferredPrompt}) {
  return (
    <BrowserRouter>
      <AuthProvider>
        {/* <ThemeProvider theme={theme}> Wrap with ThemeProvider */}
          <AppAuth deferredPrompt={deferredPrompt} />
        {/* </ThemeProvider> */}
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;